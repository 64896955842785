import axios from "api/axiosConfig";
import oriAxios from "axios";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import { environment } from "environment";

import {
  API_Create_Lesson,
  API_Create_Lesson_Image_Set,
  API_Create_Project,
  API_Create_Project_Response,
  API_Edit_Lesson,
  API_Edit_Project,
  API_Edit_Project_Response,
  API_Lesson_Checkpoint,
  API_Project,
  API_Project_Lesson,
  API_Project_Lesson_Image,
  API_Project_Lessons,
  API_Update_Checkpoint,
  API_Update_Checkpoint_Response,
  API_Update_Checkpoint_Status,
  API_Checkpoint_Status,
  ProjectCategory,
  API_Activity_Files,
  API_Project_Lesson_Activity,
  API_Edit_Activity,
  API_Create_Activity,
  API_Project_Lesson_Count,
  API_Update_Learning_Moment_Response,
  API_Update_Learning_Moment,
  API_Update_Learning_Moment_Status,
  API_Lesson_Learning_Moment_Status,
  API_Activity_Instruction,
  API_Project_Chart_Info,
  API_Project_Unit_Guide,
} from "types/project";
import { AxiosError } from "axios";
import { Lambda_Respond, NLP_Job_Status } from "types/teacher";

import { GridRowId } from "@mui/x-data-grid";

export const extractNumericId = (id: GridRowId): number => {
  if (typeof id === "string") {
    const result = id.match(/-(\d+)/);

    if (result && result[1]) {
      return parseInt(result[1], 10);
    }
  }

  // Return a default value (like -1) if no number is found or if `id` is not a string
  return -1;
};

//PROJECT
export const getAllProjects = async (
  language: string,
  accessToken: string | null
) => {
  const res = await axios.get(`${language}/cms/project/list/v2`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_Project[] = await res.data;
  return data;
};
export const getFilteredProjectList = async ({
  language,
  query,
  recommendationFilters,
  ageFilters,
  moduleFilters,
  accessToken,
  currGroupId,
  page,
  limit,
}: {
  language: string;
  query: string;
  recommendationFilters: string;
  ageFilters: string;
  moduleFilters: string;
  accessToken: string | null;
  currGroupId: number;
  page: number;
  limit: number;
}) => {
  let path = `${language}/cms/project/list/v2?recommendation_filters=${recommendationFilters}&age_filters=${ageFilters}&module_filters=${moduleFilters}&search=${query}&offset=${page}&limit=${limit}`;
  path = currGroupId === -1 ? path : path + `&group=${currGroupId}`;

  const res = await axios.get(path, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_Project[] = await res.data;
  return data;
};

export const getFilteredAssessmentProjectsList = async ({
  language,
  query,
  accessToken,
  currGroupId,
}: {
  language: string;
  query: string;
  accessToken: string | null;
  currGroupId: number;
}) => {
  // console.log("moduleSlug: ", moduleFilters);
  let path = language + `/cms/project/assessment-list/v2?search=${query}`;
  path = currGroupId === -1 ? path : path + `&group=${currGroupId}`;

  const res = await axios.get(path, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_Project[] = await res.data;
  return data;
};

export const getChildProjects = async (
  childId: number,
  language: string,
  accessToken: string
) => {
  const res = await axios.get(
    `${language}/cms/project/list/v2?child_id=${childId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data: API_Project[] = await res.data;
  return data;
};

export const checkProjectCategory = async (projectID: string) => {
  const res = await axios.get(
    `/en/cms/project/${projectID}/checklittlelearner`
  );
  const data: boolean = await res.data;
  return data;
};

export const getProjectByProjectID = async (
  language: string,
  projectID: string,
  accessToken: string | null
) => {
  try {
    const res = await axios.get(`${language}/cms/project/${projectID}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: API_Project = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getProjectById = async (
  language: string,
  projectIDs: number[],
  accessToken: string | null
) => {
  try {
    const res = await axios.get(`${language}/cms/project/list/multiple`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        projects: projectIDs.join(","), // Convert array to comma-separated string
      },
    });
    return res;
  } catch (error: unknown) {
    return "No catch!";
  }
};

export const getProjectByProjectSlug = async (
  language: string,
  projectSlug: string
) => {
  try {
    const res = await axios.get(`${language}/cms/project/slug/${projectSlug}`);
    const data: API_Project = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getProjectChartInfo = async (
  language: string,
  schoolId: number,
  projectId: number,
  startDate: string,
  endDate: string,
  accessToken: string | null
) => {
  const path =
    `${language}/cms/project/${projectId}/chart-info?` +
    (schoolId !== -1 ? `&school=${schoolId}` : "") +
    (startDate ? `&start_date=${startDate}` : "") +
    (endDate ? `&end_date=${endDate}` : "");

  const res = await axios.get(path, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_Project_Chart_Info = await res.data;
  return data;
};
export const createCategory = async (
  language: string,
  categoryData: any,
  accessToken: string | null
) => {
  const formData = new FormData();
  formData.append("name", categoryData.category);
  // formData.append("image", categoryData.image);
  formData.append("assessment", categoryData.assessment);
  try {
    const res = await axios.post(`en/cms/category/create`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    console.log(res);
    return { success: true, data: res.data, status: res.status };
  } catch (error: any) {
    console.error(error);
    return {
      success: false,
      error: error.response?.data || error.message,
      status: error.response?.status,
    };
  }
};

export const createModule = async (
  language: string,
  moduleData: any,
  accessToken: string | null
) => {
  const formData = new FormData();
  formData.append("name", moduleData.name);
  formData.append("description", moduleData.description);
  formData.append("category", moduleData.category.toString());
  formData.append("image", moduleData.image);

  try {
    const res = await axios.post(`en/cms/module/create`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    console.log(res);
    return { success: true, data: res.data, status: res.status };
  } catch (error: any) {
    console.error(error);
    return {
      success: false,
      error: error.response?.data || error.message,
      status: error.response?.status,
    };
  }
};

export const updateCategory = async (body: any, accessToken: string | null) => {
  const formData = new FormData();
  // formData.append("name", moduleData.name);
  const stringId = extractNumericId(body.id);
  console.log(stringId);
  formData.append("name", body.name);

  try {
    const res = await axios.patch(
      `en/cms/category/${stringId}/update`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return { success: true, data: res.data, status: res.status };
  } catch (error: any) {
    console.error(error);
    return {
      success: false,
      error: error.response?.data || error.message,
      status: error.response?.status,
    };
  }
};

export const updateModule = async (body: any, accessToken: string | null) => {
  const formData = new FormData();

  const stringId = extractNumericId(body.id);
  if (body.name) {
    formData.append("name", body.name);
  }
  if (body.category) {
    formData.append("category", body.category);
  }

  if (body.image) {
    formData.append("image", body.image);
  }
  try {
    const res = await axios.patch(
      `en/cms/module/${stringId}/update`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return { success: true, data: res.data, status: res.status };
  } catch (error: any) {
    console.error(error);
    return {
      success: false,
      error: error.response?.data || error.message,
      status: error.response?.status,
    };
  }
};

export const deleteModule = async (moduleId: any) => {
  try {
    const res = await axios.delete(`en/cms/module/${moduleId}/delete`);

    console.log(res);
    return { success: true, data: res.data, status: res.status };
  } catch (error: any) {
    console.error(error);
    return {
      success: false,
      error: error.response?.data || error.message,
      status: error.response?.status,
    };
  }
};

export const deleteCategory = async (categoryId: any) => {
  try {
    const res = await axios.delete(`en/cms/category/${categoryId}/delete`);

    console.log(res);
    return { success: true, data: res.data, status: res.status };
  } catch (error: any) {
    console.error(error);
    return {
      success: false,
      error: error.response?.data || error.message,
      status: error.response?.status,
    };
  }
};
export const getProjectCategories = async (language: string) => {
  const res = await axios.get(language + "/cms/category/list");

  const data: ProjectCategory[] = await res.data;

  return data;
};

export const getProjectLessons = async (
  language: string,
  projectID: string
) => {
  const res = await axios.get(language + `/cms/lesson/project/${projectID}`);
  const data: API_Project_Lessons = res.data;
  return data;
};

export const createProject = async (
  language: string,
  projectData: API_Create_Project,
  accessToken: string | null
) => {
  const res = await axios.post(language + `/cms/project/create`, projectData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_Create_Project_Response = await res.data;
  return { slug: data.slug, id: data.id };
};

export const editProject = async (
  language: string,
  projectID: number,
  projectData: API_Edit_Project
) => {
  try {
    const res = await axios.patch(
      language + `/cms/project/${projectID}/update`,
      projectData
    );
    const data: API_Edit_Project_Response = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const addOrUpdateProjectUnitGuide = async (
  language: string,
  project_unit_guides: API_Project_Unit_Guide[],
  accessToken: string | null
) => {
  const res = await axios.post(
    language + "/cms/project/unit-guide/add-or-update",
    {
      project_unit_guides: project_unit_guides,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data: API_Project_Unit_Guide = res.data;
  return data;
};

export const bulkPublishProject = async (
  publish: boolean,
  projectIDs: string[],
  accessToken: string | null
) => {
  try {
    await axios.post(
      "en/cms/project/publish",
      {
        publish,
        projects: projectIDs,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteProjectImage = async (imageID: number) => {
  try {
    await axios.delete(`/en/cms/project/delimage/${imageID}`);
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

// Objective = project-fundamental instance
export const deleteProjectObjective = async (objectiveID: number) => {
  try {
    await axios.delete(`/en/cms/project/objective/${objectiveID}/delete`);
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const projectLessonActivityNumber = async (
  projectID: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.get(`/en/cms/project/${projectID}/deleteconfirm`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: API_Project_Lesson_Count = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteProject = async (
  projectID: number,
  accessToken: string | null
) => {
  try {
    await axios.delete(`/en/cms/project/${projectID}/delete`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteProjectUnitGuide = async (projectUnitGuideId: number) => {
  try {
    const latestProjectUnitGuidesRes = await axios.delete(
      `/en/cms/project/unit-guide/${projectUnitGuideId}/delete`
    );
    return latestProjectUnitGuidesRes.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getLessonActivityResource = async (
  urls: string[],
  names: string[],
  zipName: string
) => {
  // console.log("urls: ", urls);
  if (urls.length === 1) {
    const res = await oriAxios({
      url: urls[0] + "?add-this-to-prevent-read-from-cache",
      method: "GET",
      responseType: "blob",
    }).then((response: any) => {
      // console.log("file response: ", response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${names[0]}`);
      document.body.appendChild(link);
      link.click();
    });
  } else {
    const zip = new JSZip();
    let count = 0;
    const zipFilename = zipName;

    urls.forEach(function (url, index) {
      const filename = names[index];
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(
        url + "?add-this-to-prevent-read-from-cache",
        function (err: any, data: any) {
          if (err) {
            throw err; // or handle the error
          }
          zip.file(filename, data, { binary: true });
          count++;
          if (count === urls.length) {
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, zipFilename);
            });
          }
        }
      );
    });
  }
};

export const generateProjectTags = async (
  projectID: number,
  accessToken: string | null
) => {
  try {
    const callbackUrl = `${environment.backendUrl}/en/cms/project/${projectID}/update`;
    const getRawTextAndTagListUrl = `${environment.backendUrl}/en/cms/project/${projectID}/get-raw-text-and-tag-list`;
    const res = await axios.post(
      `https://to7aow1wk3.execute-api.ap-southeast-1.amazonaws.com/dev/api/v1/text-similarity`,
      {
        get_raw_text_and_tag_list_url: getRawTextAndTagListUrl,
        threshold: "0.15",
        auth_token: accessToken,
        callback_url: callbackUrl,
      }
    );
    // const res = await axios.get(
    //   `/en/cms/project/${projectID}/generate-tags?callback_url=${callbackUrl}&auth_token=${accessToken}`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   }
    // );
    const data: Lambda_Respond = await res.data;
    console.log("data: ", data);
    if (data) {
      return data;
    } else {
      return "no job triggered";
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getTextSimilarityJobStatus = async (jobId: string) => {
  try {
    const res = await axios.post(
      `https://to7aow1wk3.execute-api.ap-southeast-1.amazonaws.com/dev/api/v1/text-similarity/status`,
      {
        job_id: jobId,
      }
      // {
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //   },
      // }
    );
    const data: NLP_Job_Status = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//LESSON
export const createLesson = async (
  language: string,
  lessonData: API_Create_Lesson
) => {
  // console.log("lessonData: ", lessonData);
  try {
    const res = await axios.post(language + `/cms/lesson/create`, lessonData);
    return res.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getLessonByID = async (language: string, lessonID: string) => {
  try {
    const res = await axios.get(language + `/cms/lesson/${lessonID}`);
    const data: API_Project_Lesson = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const editLesson = async (
  language: string,
  lessonID: number,
  lessonData: API_Edit_Lesson
) => {
  try {
    const res = await axios.patch(
      language + `/cms/lesson/${lessonID}/update`,
      lessonData
    );
    const data: API_Project_Lesson = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const addLessonImage = async (
  lessonID: number,
  fileData: API_Create_Lesson_Image_Set[]
) => {
  try {
    const res = await axios.post(
      `/en/cms/lesson/${lessonID}/addimage`,
      fileData
    );
    const data: API_Project_Lesson_Image[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteLessonImage = async (imageID: number) => {
  try {
    await axios.delete(`/en/cms/lesson/delimage/${imageID}`);
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteLessonObjective = async (objectiveID: number) => {
  try {
    await axios.delete(`/en/cms/lesson/objective/${objectiveID}/delete`);
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteLesson = async (lessonID: number) => {
  try {
    await axios.delete(`/en/cms/lesson/${lessonID}/delete`);
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//ACTIVITY
export const getActivityByID = async (language: string, activityID: string) => {
  try {
    const res = await axios.get(`${language}/cms/activity/${activityID}`);
    const data: API_Project_Lesson_Activity = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const createActivity = async (
  language: string,
  activityData: API_Create_Activity,
  accessToken: string | null
) => {
  const res = await axios.post(
    language + `/cms/activity/create`,
    activityData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data: API_Project_Lesson_Activity = await res.data;
  return data;
};

export const editActivity = async (
  language: string,
  activityID: string,
  activityData: API_Edit_Activity,
  accessToken: string | null
) => {
  try {
    const res = await axios.patch(
      language + `/cms/activity/${activityID}/update`,
      activityData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Project_Lesson_Activity = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteActivityFile = async (fileID: number) => {
  try {
    await axios.delete(`/en/cms/activity/delfile/${fileID}`);
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteLessonActivity = async (activityID: number) => {
  try {
    await axios.delete(`/en/cms/activity/${activityID}/delete`);
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteActivityInstruction = async (
  activityInstructionID: number
) => {
  try {
    const latestActivityInstructions = await axios.delete(
      `/en/cms/activity/instruction/${activityInstructionID}/delete`
    );
    return latestActivityInstructions.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const addOrUpdateActivityInstructions = async (
  language: string,
  activity_instructions: API_Activity_Instruction[],
  accessToken: string | null
) => {
  // console.log("activity_instructions: ", activity_instructions);
  const res = await axios.post(
    language + "/cms/activity/instruction/add-or-update",
    {
      activityInstructions: activity_instructions,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data: API_Activity_Instruction = res.data;
  return data;
};

export const addActivityFileWithForm = async (
  language: string,
  activityID: string,
  files: File[],
  accessToken: string | null
) => {
  const res = await axios.post(
    language + "/cms/activity/addfile",
    {
      file: files,
      activity: activityID,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data: API_Activity_Files = res.data;
  return data;
};

//LEARNING MOMENT
export const updateLessonLearningMoment = async (
  language: string,
  learningMomentData: API_Update_Learning_Moment,
  learningMomentID: number
) => {
  try {
    const res = await axios.patch(
      language + `/tms/learning-moment/${learningMomentID}/update`,
      learningMomentData
    );
    const data: API_Update_Checkpoint_Response = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const createLessonLearningMoment = async (
  language: string,
  learningMomentData: API_Update_Learning_Moment
) => {
  try {
    const res = await axios.post(
      language + `/tms/learning-moment/create`,
      learningMomentData
    );
    const data: API_Update_Learning_Moment_Response = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteLessonLearningMoment = async (learningMomentID: number) => {
  try {
    await axios.delete(`/en/tms/learning-moment/${learningMomentID}/delete`);
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getLearningMomentChildCheckList = async (
  learning_moment_id: number,
  child_id_list: number[],
  learning_moment_question_id?: number | string
) => {
  const body = {
    learning_moment_id: learning_moment_id,
    learning_moment_question_id: learning_moment_question_id,
    child_id_list: child_id_list,
  };
  try {
    const res = await axios.post(
      `en/lms/learning-moment-tracker/get-by-learning-moment`,
      body
    );
    const data: API_Lesson_Learning_Moment_Status[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateLessonLearningMomentStatus = async (
  statusData: API_Update_Learning_Moment_Status
) => {
  try {
    const res = await axios.patch(
      `/en/lms/learning-moment-tracker/update`,
      statusData
    );
    const data: API_Lesson_Learning_Moment_Status = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//CHECKPOINT
export const createCheckpoint = async (
  language: string,
  checkpointData: API_Update_Checkpoint
) => {
  try {
    const res = await axios.post(
      language + `/tms/checkpoint/create`,
      checkpointData
    );
    const data: API_Update_Checkpoint_Response = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateCheckpoint = async (
  language: string,
  checkpointData: API_Update_Checkpoint,
  checkpointID: number
) => {
  try {
    const res = await axios.patch(
      language + `/tms/checkpoint/${checkpointID}/update`,
      checkpointData
    );
    const data: API_Update_Checkpoint_Response = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getCheckpointChildCheckList = async (
  checkpoint_id: number,
  child_id_list: number[]
) => {
  const body = {
    checkpoint_id: checkpoint_id,
    child_id_list: child_id_list,
  };
  try {
    const res = await axios.post(
      `en/lms/checkpoint-tracker/get-by-checkpoint`,
      body
    );
    const data: API_Checkpoint_Status[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteLessonCheckpoint = async (checkpointID: number) => {
  try {
    await axios.delete(`/en/tms/checkpoint/${checkpointID}/delete`);
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateLessonCheckpointStatus = async (
  statusData: API_Update_Checkpoint_Status
) => {
  try {
    const res = await axios.patch(
      `/en/lms/checkpoint-tracker/update`,
      statusData
    );
    const data: API_Checkpoint_Status = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getLessonCheckpointByID = async (
  language: string,
  checkpointID: number
) => {
  try {
    const res = await axios.get(`${language}/tms/checkpoint/${checkpointID}`);
    const data: API_Lesson_Checkpoint = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//CHILD CHECKPOINT
export const getChildAllCheckpoints = async (
  language: string,
  childID: number
) => {
  try {
    const res = await axios.get(
      `${language}/lms/checkpoint-tracker/${childID}`
    );
    const data: API_Checkpoint_Status[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getChildCheckpointByProjectID = async (
  language: string,
  childID: number,
  projectID: number
) => {
  try {
    const res = await axios.get(
      `${language}/lms/checkpoint-tracker/${childID}?project=${projectID}`
    );
    const data: API_Checkpoint_Status[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getChildrenCheckpointByCheckpointID = async (
  language: string,
  childrenID: number[],
  checkpointID: number[]
) => {
  try {
    const res = await axios.post(
      `${language}/lms/checkpoint-tracker/getbycheckpoint`,
      {
        checkpoint: checkpointID,
        child: childrenID,
      }
    );
    const data: API_Checkpoint_Status[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};
