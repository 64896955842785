import { Page, Image, View } from "@react-pdf/renderer";

import { pageSize, styles } from "../ProjectReportPDF";

import Background from "images/end-of-project-report/ProjectSummaryCongratulations.png";
import { PDFInfoType } from "../Preview/constant";

const LastPage = ({
  PDFInfo,
  images,
}: {
  PDFInfo?: PDFInfoType;
  images: string[];
}) => {
  return (
    <Page size={pageSize} style={styles.body}>
      <View style={styles.main} wrap={false}>
        <Image src={Background} style={styles.background} />
      </View>
    </Page>
  );
};

export default LastPage;
