import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  IconButton,
  Switch,
  FormControlLabel,
  TextField,
  Tooltip,
  Fade,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { InputAdornment } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import TagIcon from "@mui/icons-material/Tag";
import PortraitIcon from "@mui/icons-material/Portrait";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import CropIcon from "@mui/icons-material/Crop";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {
  ModalContainer,
  HeaderContainer,
  TitleContainer,
  HeadingStyle,
  ContentContainer,
  CropBox,
  CropBoxButtonContainer,
  SaveButton,
  CancelButton,
  ActionButtonsContainer,
  ButtonText,
  ButtonCancelText,
  PhotoEditorContainer,
  ImageContainer,
  HideDateButton,
} from "./styles";
import { LearningStoryModalProps } from "./types";
import useSnack from "hooks/useSnack";
import { getAllFundamentals } from "api/fundamental-api";
import useStore from "store/store";
import { useParams } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const LearningStoryModal: React.FC<LearningStoryModalProps> = ({
  title,
  story,
  open,
  setOpen,
  image,
  image_url,
  date,
  orientation = "landscape",
  onSave,
  showPhotoInsert = false,
  showOrientation = false,
  showTags = false,
  showToggle = false,
  toggleValue = true,
  toggleLabel = "Parent View",
  onChangeToggle,
  selectedDevGoal,
  selectedMileStones,
  isLoading,
}) => {
  const maxChars = 450;
  const openSnack = useSnack();
  const [openSubGoals, setOpenSubGoals] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [devGoalOptions, setDevGoalOptions] = useState<any>([]);
  const { currLanguage } = useStore((state) => state);
  const { childID } = useParams();
  const [devGoal, setDevGoal] = useState<string>(selectedDevGoal || "");
  const [subDevGoal, setSubDevGoal] = useState<any>("");
  const [milestone, setMilestone] = useState<string>(selectedMileStones || "");
  const [error, setisError] = useState(false);

  const imageRef = useRef<HTMLImageElement>(null);
  const [loading, setLoading] = useState(false);
  const [learningStory, setStory] = useState<string | undefined>(
    story?.substring(0, maxChars)
  );
  const [storyDate, setStoryDate] = useState<string | undefined>(date);
  const [hideDate, setHideDate] = useState<boolean>(true);
  const [parentView, setParentView] = useState<boolean>(toggleValue);
  const [rotationAngle, setRotationAngle] = useState<number>(0);
  const [photoOrientation, setPhotoOrientation] = useState<
    "portrait" | "landscape"
  >(orientation);
  const [crop, setCrop] = useState<Crop | undefined>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
  const [isCropping, setIsCropping] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | undefined>(
    image_url
  );
  const [croppedFile, setCroppedFile] = useState<File | undefined>(image);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open) {
      setStory(story);
      setStoryDate(date);
      setCroppedImage(image_url);
      setCroppedFile(image);
      setDevGoal(selectedDevGoal || "");
      setMilestone(selectedMileStones || "");
    }
  }, [
    open,
    story,
    date,
    image_url,
    image,
    selectedDevGoal,
    selectedMileStones,
  ]);

  useEffect(() => {
    if (devGoal) {
      const selectedDevGoal = devGoalOptions?.find(
        (option: { id: string; mileStones?: { id: string }[] }) =>
          option.id === devGoal
      );
      if (selectedDevGoal && selectedDevGoal.mileStones?.length > 0) {
        setMilestone(selectedDevGoal.mileStones[0].id);
      } else {
      }
    }
  }, [devGoal, devGoalOptions]);

  const handleClose = () => {
    setRotationAngle(0);
    setPhotoOrientation("landscape");
    setHideDate(true);
    setParentView(true);
    setIsCropping(false);
    setCrop(undefined);
    setCompletedCrop(null);
    setLoading(false);
  };

  const handleDateChange = (newDate: Moment | null) => {
    setStoryDate(newDate ? newDate.format("YYYY-MM-DD") : undefined);
    if (error) setisError(false);
  };

  const handleReplacePhoto = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const validTypes = ["image/jpeg", "image/jpg", "image/png", "image/webp"];
      if (!validTypes.includes(file.type)) {
        alert("Only JPG, JPEG, PNG, and WEBP files are allowed.");
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        openSnack("File size should be less than 5MB", false);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const result = e.target?.result;
        if (typeof result === "string") {
          setCroppedImage(result);
          setCroppedFile(file);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeletePhoto = () => {
    setCroppedImage("");
    setCroppedFile(undefined);
  };

  const handleSubmit = async () => {
    if (
      !learningStory ||
      !storyDate ||
      !devGoal ||
      !milestone ||
      !croppedFile
    ) {
      setisError(true);
      return;
    }
    setLoading(true);

    // Call onSave with the necessary data
    await onSave({
      story: learningStory,
      date: storyDate,
      parentView,
      photo: croppedFile,
      fundamental: devGoal,
      milestone: milestone,
    });

    setLoading(false);
    handleClose();
  };

  const getCroppedImg = async (
    image: HTMLImageElement,
    crop: PixelCrop,
    fileType: "image/png" | "image/jpeg" | "image/webp" = "image/jpeg"
  ): Promise<{ file: File; url: string }> => {
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio; // For high-DPI displays

    // Create a canvas to draw the cropped image
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("Failed to get 2D context");
    }

    // Set the canvas size to the cropped area size
    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    // Draw the image onto the canvas at the cropped location
    ctx.drawImage(
      image,
      cropX,
      cropY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    // Convert the canvas to a Blob and then to a File
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }

          // Create a file from the blob
          const file = new File([blob], "cropped-image.jpg", {
            type: fileType,
          });
          const url = URL.createObjectURL(blob);

          resolve({ file, url });
        },
        fileType // This sets the output format (e.g., image/jpeg, image/png)
      );
    });
  };

  const handleOrientationChange = async () => {
    if (photoOrientation === "landscape") {
      setPhotoOrientation("portrait");
      setRotationAngle(90);
    } else {
      setPhotoOrientation("landscape");
      setCroppedImage(image_url);
      setRotationAngle(0);
    }
  };

  const renderDevGoalOptions = (options: any[]) => {
    return options.flatMap((option: any) => [
      <MenuItem key={option.id} value={option.id}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {option.name}
          {option.subDevGoal?.length > 0 && (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleToggleSubMenu(option.id);
              }}
            >
              {openSubGoals[option.id] ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownIcon />
              )}
            </IconButton>
          )}
        </Box>
      </MenuItem>,
      ...(option.subDevGoal?.length > 0 && openSubGoals[option.id]
        ? option.subDevGoal.map((subGoal: any) => (
            <MenuItem
              key={`${option.id}:${subGoal.id}`}
              value={`${option.id}:${subGoal.id}`}
              sx={{ pl: 4 }}
            >
              {subGoal.name}
            </MenuItem>
          ))
        : []),
    ]);
  };

  const handleDevGoalChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;

    if (typeof value === "string" && value.includes(":")) {
      const [mainGoal, subGoal] = value.split(":");
      setDevGoal(mainGoal);
      setSubDevGoal(subGoal);

      // Ensure IDs are compared as strings
      const selectedMainGoal = devGoalOptions.find(
        (option: { id: string | number }) => String(option.id) === mainGoal
      );

      const selectedSubGoal = selectedMainGoal?.subDevGoal.find(
        (subGoalOption: { id: string | number }) =>
          String(subGoalOption.id) === subGoal
      );

      if (selectedSubGoal && selectedSubGoal.subMileStones?.length > 0) {
        setMilestone(selectedSubGoal.subMileStones[0].id);
      } else {
        setMilestone("");
      }
    } else {
      setDevGoal(value as string);
      setSubDevGoal("");

      const selectedMainGoal = devGoalOptions.find(
        (option: { id: string | number }) => String(option.id) === value
      );

      if (selectedMainGoal && selectedMainGoal.mileStones?.length > 0) {
        setMilestone(selectedMainGoal.mileStones[0].id);
      } else {
        setMilestone("");
      }
    }
    if (error) setisError(false);
  };

  const renderMilestoneOptions = () => {
    if (subDevGoal) {
      // If a subDevGoal is selected, show its milestones
      const selectedMainGoal = devGoalOptions.find(
        (option: { id: string | number }) =>
          String(option.id) === String(devGoal)
      );

      if (selectedMainGoal) {
        const selectedSubGoal = selectedMainGoal.subDevGoal.find(
          (subGoalOption: { id: string | number }) =>
            String(subGoalOption.id) === String(subDevGoal)
        );

        if (selectedSubGoal && selectedSubGoal.subMileStones?.length > 0) {
          return selectedSubGoal.subMileStones.map(
            (milestoneOption: { id: string; name: string }) => (
              <MenuItem key={milestoneOption.id} value={milestoneOption.id}>
                {milestoneOption.name}
              </MenuItem>
            )
          );
        } else {
          return null;
        }
      }
    } else {
      // Otherwise, show the main goal's milestones
      const selectedMainGoal = devGoalOptions.find(
        (option: { id: string | number }) =>
          String(option.id) === String(devGoal)
      );

      if (selectedMainGoal && selectedMainGoal.mileStones?.length > 0) {
        return selectedMainGoal.mileStones.map(
          (milestoneOption: { id: string; name: string }) => (
            <MenuItem key={milestoneOption.id} value={milestoneOption.id}>
              {milestoneOption.name}
            </MenuItem>
          )
        );
      } else {
        return null;
      }
    }
  };

  const handleMilestoneChange = (event: SelectChangeEvent<string>) => {
    setMilestone(event.target.value);
    if (error) setisError(false);
  };

  const populateFundamentals = async () => {
    const res = await getAllFundamentals(
      currLanguage,
      undefined,
      Number(childID)
    );

    if (typeof res !== "string") {
      const filteredFundamentals = res.map((fundamental: any) => {
        // Combine milestones from fundamentals and sub_fundamentals
        const combinedMilestones = [
          ...fundamental.milestones,
          ...fundamental.sub_fundamentals.flatMap(
            (subDevGoal: any) => subDevGoal.milestones
          ),
        ].map((milestone: any) => ({
          id: milestone.id,
          name: milestone.name,
        }));

        return {
          id: fundamental.id,
          name: fundamental.name,
          subDevGoal: fundamental.sub_fundamentals.map((subDevGoal: any) => ({
            id: subDevGoal.id,
            name: subDevGoal.name,
            subMileStones: subDevGoal.milestones.map((milestone: any) => ({
              id: milestone.id,
              name: milestone.name,
            })),
          })),
          mileStones: combinedMilestones,
        };
      });

      setDevGoalOptions(filteredFundamentals);
    }
  };

  useEffect(() => {
    populateFundamentals();
  }, [currLanguage, childID]);

  // Function to toggle the sub-menu for each developmental goal
  const handleToggleSubMenu = (goalId: string) => {
    setOpenSubGoals((prev) => ({
      ...prev,
      [goalId]: !prev[goalId], // Toggle the current state
    }));
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      sx={{
        m: "24px",
      }}
    >
      <Fade in={open}>
        <ModalContainer>
          {isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 10,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <LoadingIndicator />
            </Box>
          )}
          <HeaderContainer>
            <TitleContainer>
              <IconButton onClick={handleClose}>
                <ArrowBackIcon />
              </IconButton>
              <HeadingStyle>{title}</HeadingStyle>
            </TitleContainer>
            {showToggle && (
              <FormControlLabel
                control={
                  <Tooltip title={toggleLabel} placement="top">
                    <Switch
                      checked={parentView}
                      onChange={onChangeToggle}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Tooltip>
                }
                label={toggleLabel}
                componentsProps={{
                  typography: {
                    sx: {
                      fontFamily: "Futura PT",
                      fontSize: "16px",
                      fontWeight: 400,
                    },
                  },
                }}
              />
            )}
          </HeaderContainer>
          <ContentContainer>
            {isCropping ? (
              <CropBox>
                <ReactCrop
                  crop={crop}
                  onChange={(newCrop) => setCrop(newCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                >
                  <img
                    ref={imageRef}
                    src={
                      typeof croppedImage === "string"
                        ? croppedImage
                        : image && URL.createObjectURL(image)
                    }
                    alt="Learning Story"
                    style={{ transform: `rotate(${rotationAngle}deg)` }}
                    crossOrigin="anonymous"
                  />
                </ReactCrop>

                <CropBoxButtonContainer>
                  <CancelButton
                    onClick={() => setIsCropping(false)}
                    variant="outlined"
                  >
                    <ButtonCancelText>Cancel</ButtonCancelText>
                  </CancelButton>
                  <SaveButton
                    onClick={async () => {
                      if (completedCrop && imageRef.current) {
                        const croppedResult = await getCroppedImg(
                          imageRef.current,
                          completedCrop
                        );
                        setCroppedImage(croppedResult.url);
                        setCroppedFile(croppedResult.file);
                      }
                      setIsCropping(false);
                    }}
                    variant="outlined"
                  >
                    <ButtonText>Done</ButtonText>
                  </SaveButton>
                </CropBoxButtonContainer>
              </CropBox>
            ) : (
              <ImageContainer
                sx={{
                  backgroundColor: !croppedImage ? "#91C6C5" : "transparent",
                  height: photoOrientation === "landscape" ? "336px" : "528px",
                }}
              >
                {croppedImage || croppedFile ? (
                  <img
                    src={
                      croppedImage ||
                      (croppedFile && URL.createObjectURL(croppedFile))
                    }
                    alt="Learning Story"
                    style={{
                      top: 0,
                      width: "100%",
                      height: "100%",
                      transition: "transform 0.3s ease",
                      transform: `rotate(${rotationAngle}deg)`,
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      height: "336px",
                      width: "528px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      border: error && !croppedImage ? "1px solid red" : "none",
                    }}
                    onClick={handleReplacePhoto}
                  >
                    <AddAPhotoOutlinedIcon
                      sx={{ fontSize: 100, color: "white" }}
                    />
                    {error && !croppedFile && (
                      <Box sx={{ color: "red" }}>Image is required.</Box>
                    )}
                  </Box>
                )}
              </ImageContainer>
            )}
            <PhotoEditorContainer>
              {showPhotoInsert && (
                <Tooltip
                  title="Replace Picture"
                  placement="top"
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -20],
                        },
                      },
                    ],
                  }}
                >
                  <IconButton onClick={handleReplacePhoto}>
                    <AddAPhotoOutlinedIcon
                      style={{ width: "32px", height: "32px" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {croppedImage !== "" && (
                <>
                  {showOrientation && (
                    <Tooltip
                      title="Orientation"
                      placement="top"
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -20],
                            },
                          },
                        ],
                      }}
                    >
                      <IconButton onClick={handleOrientationChange}>
                        {photoOrientation === "landscape" ? (
                          <InsertPhotoOutlinedIcon
                            style={{ width: "32px", height: "32px" }}
                          />
                        ) : (
                          <PortraitIcon
                            style={{ width: "32px", height: "32px" }}
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    title="Crop Picture"
                    placement="top"
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -20],
                          },
                        },
                      ],
                    }}
                  >
                    <IconButton onClick={() => setIsCropping(true)}>
                      <CropIcon style={{ width: "32px", height: "32px" }} />
                    </IconButton>
                  </Tooltip>
                  {showTags && (
                    <Tooltip
                      title="Tag"
                      placement="top"
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -20],
                            },
                          },
                        ],
                      }}
                    >
                      <IconButton onClick={() => {}}>
                        <TagIcon style={{ width: "32px", height: "32px" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    title="Delete Photo"
                    placement="top"
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -20],
                          },
                        },
                      ],
                    }}
                  >
                    <IconButton onClick={handleDeletePhoto}>
                      <DeleteIcon style={{ width: "32px", height: "32px" }} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </PhotoEditorContainer>
          </ContentContainer>
          {!isCropping && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                margin: "16px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="dev-goal-select-label">
                  Developmental Goal
                </InputLabel>
                <Select
                  labelId="dev-goal-select-label"
                  id="dev-goal-select"
                  value={devGoal || ""} // Ensure value is never undefined or null
                  label="Developmental Goal"
                  onChange={handleDevGoalChange}
                  sx={{ border: error && !devGoal ? "1px solid red" : "none" }}
                  renderValue={(value) => {
                    let name = "";

                    if (devGoal) {
                      // Find the selected main goal
                      const selectedMainGoal = devGoalOptions.find(
                        (option: { id: string | number }) =>
                          String(option.id) === String(devGoal)
                      );

                      if (
                        subDevGoal &&
                        selectedMainGoal &&
                        selectedMainGoal.subDevGoal
                      ) {
                        // If subDevGoal is selected, find the corresponding sub goal
                        const selectedSubGoal =
                          selectedMainGoal.subDevGoal.find(
                            (subGoalOption: { id: string | number }) =>
                              String(subGoalOption.id) === String(subDevGoal)
                          );
                        // If found, format the name as "Main Goal - Sub Goal"
                        name = selectedSubGoal
                          ? `${selectedMainGoal.name} - ${selectedSubGoal.name}`
                          : selectedMainGoal.name;
                      } else {
                        // If only main goal is selected, use the main goal's name
                        name = selectedMainGoal ? selectedMainGoal.name : "";
                      }
                    }

                    return name || "";
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5,
                        width: "auto",
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                >
                  {renderDevGoalOptions(devGoalOptions)}
                </Select>

                {error && !devGoal && (
                  <Box sx={{ color: "red", marginTop: "4px" }}>
                    Developmental Goal is required.
                  </Box>
                )}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="milestone-select-label">Milestone</InputLabel>
                <Select
                  labelId="milestone-select-label"
                  id="milestone-select"
                  value={milestone || ""}
                  label="Milestone"
                  onChange={handleMilestoneChange}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5,
                      },
                    },
                  }}
                  sx={{
                    border: error && !milestone ? "1px solid red" : "none",
                  }}
                >
                  {renderMilestoneOptions()}
                </Select>
                {error && !milestone && (
                  <Box sx={{ color: "red", marginTop: "4px" }}>
                    Milestone is required.
                  </Box>
                )}
              </FormControl>

              <TextField
                label="Story"
                value={learningStory}
                onChange={(e) => {
                  const inputText = e.target.value;
                  if (inputText.length <= maxChars) {
                    setStory(inputText);
                  } else {
                    setStory(inputText.slice(0, maxChars));
                  }
                  if (error) setisError(false);
                }}
                fullWidth
                multiline
                variant="outlined"
                helperText={
                  error && !learningStory ? (
                    <span style={{ color: "red" }}>Story is required.</span>
                  ) : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span
                        style={{
                          position: "absolute",
                          right: 8,
                          bottom: 0,
                          color:
                            learningStory?.length === maxChars
                              ? "red"
                              : "inherit",
                          fontSize: "0.7rem",
                        }}
                      >
                        {learningStory?.length || 0}/{maxChars}
                      </span>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  color:
                    error && !learningStory
                      ? "red"
                      : "var(--Neutral-900, #323031)",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        error && !learningStory
                          ? "red"
                          : "var(--Neutral-900, #323031)",
                      borderRadius: "4px",
                    },
                    "&:hover fieldset": {
                      borderColor: "var(--Neutral-900, #323031)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "var(--Cyan-700, #5c9391)",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color:
                      error && !learningStory
                        ? "red"
                        : "var(--Neutral-900, #323031)",
                    fontFamily: "Futura PT",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "22px",
                  },
                }}
              />

              {hideDate && (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Select Date"
                    value={storyDate ? moment(storyDate, "YYYY-MM-DD") : null}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          error && !storyDate ? (
                            <span style={{ color: "red" }}>
                              Date is required.
                            </span>
                          ) : (
                            ""
                          )
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor:
                                error && !storyDate
                                  ? "red"
                                  : "var(--Neutral-900, #323031)",
                              borderRadius: "4px",
                            },
                            "&:hover fieldset": {
                              borderColor: "var(--Neutral-900, #323031)",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "var(--Cyan-700, #5c9391)",
                            },
                          },
                          "& .MuiInputBase-input": {
                            color: "var(--Neutral-900, #323031)",
                            fontFamily: "Futura PT",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "22px",
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  cursor: "pointer",
                }}
                onClick={() => setHideDate(!hideDate)}
              >
                <HideDateButton>
                  {!hideDate ? "Show Date" : "Hide Details"}
                </HideDateButton>
              </Box>
            </Box>
          )}
          {!isCropping && (
            <ActionButtonsContainer>
              <CancelButton
                onClick={() => {
                  handleClose();
                  setOpen(false);
                }}
                variant="outlined"
                disabled={loading}
              >
                <ButtonCancelText>Cancel</ButtonCancelText>
              </CancelButton>
              <SaveButton onClick={handleSubmit} variant="outlined">
                <ButtonText>Save</ButtonText>
              </SaveButton>
            </ActionButtonsContainer>
          )}
          <input
            ref={fileInputRef}
            type="file"
            accept=".jpg,.jpeg,.png,.webp"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </ModalContainer>
      </Fade>
    </Modal>
  );
};

export default LearningStoryModal;
