import { Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer";

import { GalleryImage } from "components/ProjectSummaryScreen/ProjectSummaryScreen";
import { pageSize, imageSrc, styles } from "../ProjectReportPDF";
import { LessonType } from "../Preview/constant";

import Background from "images/end-of-project-report/two-polaroids.png";
import Wave from "images/end-of-project-report/wave.png";
import { checkIsChineseChar } from "helper/helper";
import { fontFamily } from "@mui/system";

const templateStyles = StyleSheet.create({
  images: {
    position: "absolute",
    width: 400,
    height: 350,
    objectFit: "cover",
    transformOrigin: "top left",
  },
  image1: {
    top: 123,
    left: 265,
    transform: "rotate(3deg)",
  },
  image2: {
    top: 141,
    left: 771,
    transform: "rotate(-2deg)",
  },
  title: {
    position: "absolute",
    bottom: 120,
    left: 40,
    right: 40,
  },
  caption: {
    position: "absolute",
    top: 702,
    left: 40,
    right: 40,
  },
  fontStyleChinese: {
    fontFamily: "KaiTi",
  },
});

const LT2Images = ({
  lesson,
  gallery,
}: {
  lesson?: LessonType;
  gallery: GalleryImage;
}) => {
  const hyphenationCallback = (word: string) => {
    if (word.length === 1 || !checkIsChineseChar(word)) {
      return [word];
    }
    return Array.from(word)
      .map((char) => [char, ""])
      .reduce((arr, current) => {
        arr.push(...current);
        return arr;
      }, []);
  };

  Font.registerHyphenationCallback(hyphenationCallback);

  const titleText = lesson ? lesson.title : gallery.lesson;

  return (
    <Page size={pageSize} style={styles.body}>
      <View style={styles.main} wrap={false}>
        <Image src={Wave} style={styles.background} />

        <Image
          src={imageSrc(lesson ? lesson.images[0].file : gallery.images[0].src)}
          style={[templateStyles.images, templateStyles.image1]}
        />

        <Image
          src={imageSrc(lesson ? lesson.images[1].file : gallery.images[1].src)}
          style={[templateStyles.images, templateStyles.image2]}
        />

        <Image src={Background} style={styles.background} />

        {checkIsChineseChar(titleText) ? (
          <Text
            style={[
              styles.pageTitle,
              templateStyles.title,
              templateStyles.fontStyleChinese,
            ]}
          >
            titleText
          </Text>
        ) : (
          <Text style={[styles.pageTitle, templateStyles.title]}>
            {titleText}
          </Text>
        )}

        {checkIsChineseChar(lesson?.caption) ? (
          <Text
            style={{
              textAlign: "center",
              fontFamily: "KaiTi",
              ...templateStyles.caption,
            }}
          >
            {lesson ? lesson.caption : gallery.images[0].alt}
          </Text>
        ) : (
          <Text style={[styles.textCenter, templateStyles.caption]}>
            {lesson ? lesson.caption : gallery.images[0].alt}
          </Text>
        )}
      </View>
    </Page>
  );
};

export default LT2Images;
