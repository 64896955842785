import { API_Project, Age_Group } from "../types/project";
import { StoreSlice } from "./store";

export interface MarketPlaceSlice {
  projects: API_Project[];
  moduleFilters: string[];
  recommendationFilters: string[];
  ageFilters: string[];
  currentAgeGroup: string | null;
  setProjects: (
    projects: API_Project[] | ((prevProjects: API_Project[]) => API_Project[])
  ) => void;
  setModuleFilters: (moduleFilters: string[]) => void;
  setRecommendationFilters: (recommendationFilters: string[]) => void;
  setAgeFilters: (ageFilters: string[]) => void;
  setCurrentAgeGroup: (ageGroup: string | null) => void;
}

export const createMarketPlaceSlice: StoreSlice<MarketPlaceSlice> = (
  set,
  get
) => ({
  projects: [],
  moduleFilters: [],
  recommendationFilters: [],
  ageFilters: [],
  currentAgeGroup: null,
  setProjects: (
    projectsOrUpdater:
      | API_Project[]
      | ((prevProjects: API_Project[]) => API_Project[])
  ) => {
    set((state: MarketPlaceSlice) => ({
      ...state,
      projects:
        typeof projectsOrUpdater === "function"
          ? projectsOrUpdater(state.projects)
          : projectsOrUpdater,
    }));
  },
  setModuleFilters: (moduleFilters: string[]) => {
    set((prev: MarketPlaceSlice) => ({
      ...prev,
      moduleFilters: moduleFilters,
    }));
  },
  setRecommendationFilters: (recommendationFilters: string[]) => {
    set((prev: MarketPlaceSlice) => ({
      ...prev,
      recommendationFilters: recommendationFilters,
    }));
  },
  setAgeFilters: (ageFilters: string[]) => {
    set((prev: MarketPlaceSlice) => ({
      ...prev,
      ageFilters: ageFilters,
    }));
  },
  setCurrentAgeGroup: (ageGroup) => {
    set((state) => ({ ...state, currentAgeGroup: ageGroup }));
  },
});
