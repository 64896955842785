import { InferType, number, object, string } from 'yup';
// This file is used to centralize the configuration of environment variables.
// It uses Yup for validation and provides a default value for each variable.
// To use an environment variable, import the `environment` object from this file.

export const environmentSchema = object().shape({
  backendUrl: string().required(),
  socketUrl: string().required(),
  rateLimitBlockMinutes: number().default(5),
  previewFeatures: string().oneOf(['enabled', 'disabled']).default('disabled'),
});

export type Environment = InferType<typeof environmentSchema>;

export const environment = environmentSchema.validateSync({
  backendUrl: process.env.REACT_APP_API_URL,
  socketUrl: process.env.REACT_APP_SOCKET_URL,
  rateLimitBlockMinutes: process.env.REACT_APP_RATE_LIMIT_BLOCK_MINUTES,
  previewFeatures: process.env.REACT_APP_FEATURE,
});

console.log('Validated backendUrl:', environment.backendUrl);