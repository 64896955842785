import { useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";

import FilterListIcon from "@mui/icons-material/FilterList";
import useStore from "store/store";

import {
  Box,
  Container,
  Typography,
  Drawer,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import DetailCard from "components/DetailCard/DetailCard";
import SearchBar from "components/SearchBar/SearchBar";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import RecommendationSection from "./MarketPlaceSearchSections/RecommendationSection";
import ModuleSection from "./MarketPlaceSearchSections/ModuleSection";
import { getFilteredProjectList } from "api/project-api";

import { API_Project } from "types/project";

// Prediscovery
import AgeSection from "./MarketPlaceSearchSections/AgeSection";
import QuickQuestionsModal from "containers/QuickQuestions/QuickQuestionsModal";
import ProjectModal from "components/ProjectModal/ProjectModal";
import { translation } from "constants/translation";

const MarketPlaceSearch = () => {
  const { languageSlug } = useParams();
  const {
    projects,
    setProjects,
    currGroup,
    recommendationFilters,
    ageFilters,
    moduleFilters,
    currLanguage,
    currGroupFavourite,
    setRefreshGroupFavourite,
    currGroupBackpack,
    setRefreshGroupBackpack,
    setCurrentAgeGroup,
    currentAgeGroup,
  } = useStore((state) => ({
    ...state,
  }));

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true); // New state for initial loading
  const [open, setOpen] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<number>(-1);
  const [query, setQuery] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null); // Ref for scroll container

  // Fetch age group from local storage and update store if needed
  useEffect(() => {
    const storedAgeGroup = localStorage.getItem("currentAgeGroup");
    if (storedAgeGroup && storedAgeGroup !== currentAgeGroup) {
      setCurrentAgeGroup(storedAgeGroup);
    }
  }, [currentAgeGroup, setCurrentAgeGroup]);

  const updateData = async (currGroupId: number, reset?: boolean) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const accessToken = localStorage.getItem("access");

    const updatedProj: API_Project[] = await getFilteredProjectList({
      language: currLanguage,
      query: query !== null ? query : "",
      recommendationFilters: recommendationFilters.join(","),
      ageFilters: ageFilters.join(","),
      moduleFilters: moduleFilters.join(","),
      accessToken,
      currGroupId,
      page: reset ? 0 : page,
      limit,
    });

    // First, filter out projects with assessments
    let filteredProjects = updatedProj.filter((proj) => !proj.assessment);

    let recommendedAgeGroup = localStorage.getItem("currentAgeGroup");

    if (recommendedAgeGroup) {
      // Then sort the projects based on currentAgeGroup
      filteredProjects.sort((a, b) => {
        if (a.age === recommendedAgeGroup && b.age !== recommendedAgeGroup) {
          return -1; // a comes first
        }
        if (a.age !== recommendedAgeGroup && b.age === recommendedAgeGroup) {
          return 1; // b comes first
        }
        return 0; // no change in order for items with the same or irrelevant age group
      });
    }

    setHasMore(updatedProj.length === limit);

    if (reset) {
      setProjects(filteredProjects);
    } else {
      setProjects((prevProjects) => [...prevProjects, ...filteredProjects]);
    }

    setRefreshGroupFavourite(true);
    setRefreshGroupBackpack(true);
    setIsLoading(false);
    setIsInitialLoading(false); // Set initial loading to false after data is fetched
    if (!reset) {
      setPage(page + limit);
    } else {
      setPage(limit);
    }
  };

  useEffect(() => {
    if (query === null) return;

    const searchTimeout = setTimeout(async () => {
      const currGroupId = localStorage.getItem("curr_group_id");
      if (currGroupId) {
        updateData(Number(currGroupId), true);
      } else {
        setIsLoading(false);
        setIsInitialLoading(false);
      }
    }, 1200);
    return () => clearTimeout(searchTimeout);
  }, [query]);

  function reset() {
    setPage(0);
    setIsInitialLoading(true);
    const currGroupId = localStorage.getItem("curr_group_id");
    if (currGroupId) {
      updateData(Number(currGroupId), true);
    }
  }

  useEffect(() => {
    reset();
  }, [
    languageSlug,
    recommendationFilters,
    moduleFilters,
    ageFilters,
    currGroup,
  ]);

  const groupedProjects: { [category: string]: API_Project[] } =
    projects.reduce(
      (grouped, proj) => {
        const category = proj.module?.name; // Using 'name' property of module
        if (!grouped[category]) {
          grouped[category] = [];
        }
        grouped[category].push(proj);
        return grouped;
      },
      {} as { [category: string]: API_Project[] } // Add this type assertion
    );

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletView = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLaptopView = useMediaQuery(theme.breakpoints.between("md", "lg"));

  useEffect(() => {
    if (isMobileView) {
      setLimit(5);
    } else if (isTabletView) {
      setLimit(8);
    } else if (isLaptopView) {
      setLimit(10);
    } else {
      setLimit(15);
    }
  }, [isMobileView, isTabletView, isLaptopView]);

  const fetchData = useCallback(async () => {
    const currGroupId = localStorage.getItem("curr_group_id");
    if (currGroupId) {
      await updateData(Number(currGroupId));
    }
  }, [page, isLoading]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        const { scrollTop, clientHeight, scrollHeight } = scrollContainer;

        if (
          clientHeight !== scrollHeight &&
          scrollTop + clientHeight >= scrollHeight - 20 &&
          hasMore
        ) {
          fetchData();
        }
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetchData]);

  return (
    <>
      <QuickQuestionsModal />

      {isMobileView && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px 20px", // padding to search bar and filter icon
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <SearchBar handleChange={(query: string) => setQuery(query)} />
              <IconButton onClick={() => setIsFilterDrawerOpen(true)}>
                <FilterListIcon />
              </IconButton>
            </div>
          </Box>

          <Drawer
            anchor="right"
            open={isFilterDrawerOpen}
            onClose={() => setIsFilterDrawerOpen(false)}
            PaperProps={{
              sx: {
                padding: "0 16px", // Add padding to the left and right sides of drawer
              },
            }}
          >
            <RecommendationSection />
            <AgeSection />
            <ModuleSection />
          </Drawer>
        </>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        {!isMobileView && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "300px",
              minWidth: "300px",
              p: "24px",
              backgroundColor: "neutral.100",
              borderRight: "1px solid",
              borderColor: "neutral.300",
              overflowY: "auto", // Enables scrolling for sidebar
              maxHeight: "calc(100vh - 64px)",
            }}
          >
            <Typography sx={{ fontWeight: "500", mb: 1 }}>
              {translation.browseL}
            </Typography>
            <SearchBar
              sx={{ width: "252px" }}
              handleChange={(query: string) => setQuery(query)}
            />
            <RecommendationSection />
            <AgeSection />
            <ModuleSection />
          </Box>
        )}
        <Container
          ref={scrollContainerRef} // Set the ref here
          id="scroll-container"
          sx={{
            overflowY: "auto", // Enables scrolling
            maxHeight: "calc(100vh - 64px)", // Ensure it fits within the viewport
            backgroundColor: "#FBFBFB",
            borderRadius: "10px",
            p: 3,
            minWidth: { xs: "200px", lg: "900px" },
          }}
          maxWidth={false}
        >
          {isInitialLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <LoadingIndicator />
            </Box>
          ) : (
            <Box>
              {isMobileView &&
                Object.entries(groupedProjects).map(
                  ([category, categoryProjects]) => (
                    <div key={category}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                        }}
                      >
                        {category}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection:
                            (moduleFilters.length ||
                              recommendationFilters.length ||
                              ageFilters.length) === 1
                              ? "column"
                              : "row",
                          justifyContent:
                            (moduleFilters.length ||
                              recommendationFilters.length ||
                              ageFilters.length) === 1
                              ? "center"
                              : "none",
                          alignItems:
                            (moduleFilters.length ||
                              recommendationFilters.length ||
                              ageFilters.length) === 1
                              ? "center"
                              : "none",
                          overflow: "auto",
                          gap: "1rem",
                          padding: "1rem",
                          marginBottom: "2rem",
                          marginRight: "-.1em",
                          overflowX: "scroll", // Enable horizontal scrolling
                          "-ms-overflow-style": "none", // Hide scrollbar in IE and Edge
                          "&::-webkit-scrollbar": {
                            width: "0px", // Hide scrollbar in Chrome and Safari
                            height: "0px",
                          },
                        }}
                      >
                        {categoryProjects.map((proj) => (
                          <DetailCard
                            project={proj}
                            key={`${proj.id}-${currGroupFavourite.projects}-${currGroupBackpack.projects}`}
                            onClick={() => {
                              setOpen(true);
                              setProjectId(proj.id);
                            }}
                          />
                        ))}
                      </Box>
                    </div>
                  )
                )}

              {!isMobileView &&
                (projects.length !== 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      gap: 3,
                    }}
                  >
                    {projects.map((proj) => (
                      <DetailCard
                        project={proj}
                        key={`${proj.id}-${currGroupFavourite.projects}-${currGroupBackpack.projects}`}
                        onClick={() => {
                          setOpen(true);
                          setProjectId(proj.id);
                        }}
                      />
                    ))}
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      textAlign: "center",
                    }}
                  >
                    No projects found.
                  </Typography>
                ))}
            </Box>
          )}

          {!isInitialLoading && isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "1rem",
              }}
            >
              <LoadingIndicator />
            </Box>
          )}

          {open && projectId !== -1 && (
            <ProjectModal
              open={open}
              setOpen={setOpen}
              projectId={projectId}
              setProjectId={setProjectId}
            />
          )}
        </Container>
      </Box>
    </>
  );
};

export default MarketPlaceSearch;
