import { Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer";

import { GalleryImage } from "components/ProjectSummaryScreen/ProjectSummaryScreen";
import { pageSize, imageSrc, styles } from "../ProjectReportPDF";
import { LessonType } from "../Preview/constant";

import Background from "images/end-of-project-report/five-img-bg.png";
import Wave from "images/end-of-project-report/wave.png";
import { checkIsChineseChar } from "helper/helper";

const templateStyles = StyleSheet.create({
  images: {
    position: "absolute",
    width: 280,
    height: 280,
    objectFit: "contain",
    objectPosition: "50% 0",
    transformOrigin: "top left",
  },
  image1: {
    top: 385,
    left: 40,
    transform: "rotate(5.57deg)",
  },
  image2: {
    top: 253,
    left: 305,
    transform: "rotate(-10.22deg)",
  },
  image3: {
    top: 498,
    left: 613,
    transform: "rotate(3.35deg)",
  },
  image4: {
    top: 197,
    left: 919,
    transform: "rotate(22.55deg)",
  },
  image5: {
    top: 511,
    left: 1116,
    transform: "rotate(-9.22deg)",
  },
  title: {
    position: "absolute",
    top: 40,
    left: 40,
    right: 40,
  },
  caption: {
    position: "absolute",
    top: 102,
    left: 40,
    right: 40,
  },
  fontStyleChinese: {
    fontFamily: "KaiTi",
  },
});

const LT5Images = ({
  lesson,
  gallery,
}: {
  lesson?: LessonType;
  gallery: GalleryImage;
}) => {
  const hyphenationCallback = (word: string) => {
    if (word.length === 1 || !checkIsChineseChar(word)) {
      return [word];
    }
    return Array.from(word)
      .map((char) => [char, ""])
      .reduce((arr, current) => {
        arr.push(...current);
        return arr;
      }, []);
  };

  Font.registerHyphenationCallback(hyphenationCallback);

  const titleText = lesson ? lesson.title : gallery.lesson;

  return (
    <Page size={pageSize} style={styles.body}>
      <View style={styles.main} wrap={false}>
        <Image
          src={Wave}
          style={[styles.background, styles.backgroundReverse]}
        />

        <Image
          src={imageSrc(lesson ? lesson.images[0].file : gallery.images[0].src)}
          style={[templateStyles.images, templateStyles.image1]}
        />

        <Image
          src={imageSrc(lesson ? lesson.images[1].file : gallery.images[1].src)}
          style={[templateStyles.images, templateStyles.image2]}
        />

        <Image
          src={imageSrc(lesson ? lesson.images[2].file : gallery.images[2].src)}
          style={[templateStyles.images, templateStyles.image3]}
        />

        <Image
          src={imageSrc(lesson ? lesson.images[3].file : gallery.images[3].src)}
          style={[templateStyles.images, templateStyles.image4]}
        />

        <Image
          src={imageSrc(lesson ? lesson.images[4].file : gallery.images[4].src)}
          style={[templateStyles.images, templateStyles.image5]}
        />

        <Image src={Background} style={styles.background} />

        {checkIsChineseChar(titleText) ? (
          <Text
            style={[
              styles.pageTitle,
              templateStyles.title,
              templateStyles.fontStyleChinese,
            ]}
          >
            {titleText}
          </Text>
        ) : (
          <Text style={[styles.pageTitle, templateStyles.title]}>
            {titleText}
          </Text>
        )}
        {checkIsChineseChar(lesson?.caption) ? (
          <Text
            style={{
              textAlign: "center",
              fontFamily: "KaiTi",
              ...templateStyles.caption,
            }}
          >
            {lesson ? lesson.caption : gallery.images[0].alt}
          </Text>
        ) : (
          <Text style={[styles.textCenter, templateStyles.caption]}>
            {lesson ? lesson.caption : gallery.images[0].alt}
          </Text>
        )}
      </View>
    </Page>
  );
};

export default LT5Images;
