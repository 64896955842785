import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { pdf, Font } from "@react-pdf/renderer";

import Button, { DownloadButton } from "../Button/Button";
import { translation } from "constants/translation";

import ChildrenPortfolioPDF from "./ChildrenPortfolioPDF";
import { PDFInfoType } from "./Preview/constant";
import useStore from "store/store";
// import { saveAs } from "file-saver";
import {
  createChildProgressReport,
  deleteChildProgressReport,
} from "api/report-api";
import { useNavigate, useParams } from "react-router-dom";
import { CHILDREN_PORTFOLIO_PAGE, CURLANG } from "constants/url";
import { slugify } from "helper/slugify";
import { PROGRESS_REPORT } from "containers/ChildrenPortfolioPage/constants";
import useSnack from "hooks/useSnack";
import NotoSansSCMediumFont from "../../fonts/NotoSansSC-Medium.ttf";

Font.register({
  family: "NotoSansSC-Medium",
  src: NotoSansSCMediumFont,
});

const sequentialMessages = [
  "📚 Collecting learning moments...",
  "📝 Writing comments...",
  "🌟 Adding gold stars...",
  "✏️ Sharpening pencils...",
  "📊 Crunching numbers...",
  "🎨 Adding colorful stickers...",
];

const DownloadPDFButton = ({
  PDFInfo,
  isDownload = true,
  isProjectReportReady = false,
  modalClosed = false,
}: {
  PDFInfo: PDFInfoType;
  isDownload?: boolean;
  isProjectReportReady?: boolean;
  modalClosed?: boolean;
}) => {
  const navigate = useNavigate();
  const openSnack = useSnack();
  const { groupSlug, childID, fileId } = useParams();
  const { currLanguage, developmentalGoals } = useStore((state) => state);

  const [isGeneratingPDF, setIsGeneratingPDF] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [isCreatingReport, setIsCreatingReport] = useState<boolean>(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(0);

  const downloadPDF = async () => {
    if (isGeneratingPDF) return;
    setIsGeneratingPDF(true);

    try {
      const blob = await pdf(
        <ChildrenPortfolioPDF
          PDFInfo={PDFInfo}
          currentLanguage={currLanguage}
          developmentalGoals={developmentalGoals}
        />
      ).toBlob();

      if (isDownload) {
        await savePDFFile(blob);
        if (fileId) {
          await deleteChildProgressReport(
            Number(fileId),
            localStorage.getItem("access") || ""
          );
        }
      } else {
        openPDFInNewTab(blob);
      }
    } catch (error) {
      console.error("Error generating or handling PDF:", error);
      openSnack("Error generating report. Please try again.", false);
    } finally {
      setIsGeneratingPDF(false);
    }
  };

  const savePDFFile = async (blob: Blob) => {
    try {
      const res = await createChildProgressReport(
        childID!,
        groupSlug!,
        PDFInfo.startMonth,
        PDFInfo.endMonth,
        setIsCreatingReport,
        setProgressPercentage,
        "",
        "",
        blob,
        `${PDFInfo.profileName}_${PDFInfo.startMonth}_To_${PDFInfo.endMonth}_Report`
      );
      if (typeof res !== "string") {
        navigate(
          `${CURLANG(currLanguage)}/${CHILDREN_PORTFOLIO_PAGE(
            groupSlug!,
            childID!
          )}/${slugify(PROGRESS_REPORT)}`
        );
        openSnack("Project Summary Report is generated!", true);
      } else {
        throw new Error("Failed to create progress report");
      }
    } catch (error) {
      console.error("Error saving PDF file:", error);
      openSnack("Error saving report. Please try again.", false);
    }
  };

  const openPDFInNewTab = async (blob: Blob) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onloadend = () => {
      const pdfArrayBuffer = reader.result as ArrayBuffer;
      const pdfBlob = new Blob([pdfArrayBuffer], { type: "application/pdf" });
      const pdfObjectUrl = URL.createObjectURL(pdfBlob);
      const newTab = window.open();
      if (newTab) {
        newTab.document.write(
          `<iframe src="${pdfObjectUrl}" width="100%" height="100%"></iframe>`
        );
      } else {
        console.error("Unable to open new tab");
        openSnack(
          "Unable to open PDF. Please check your browser settings.",
          false
        );
      }
    };
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (modalClosed) {
      timeout = setTimeout(() => {
        if (currentMessageIndex < sequentialMessages.length - 1) {
          setCurrentMessageIndex((prevIndex) => prevIndex + 1);
        }
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [currentMessageIndex, modalClosed]);

  return (
    <Box
      sx={{
        width: "100%",
      }}
      onClick={downloadPDF}
    >
      {!isGeneratingPDF ? (
        isDownload ? (
          isProjectReportReady || !modalClosed ? (
            <DownloadButton
              btnText={"Generate Report"}
              style={{ width: "100%", cursor: "pointer", textAlign: "center" }}
            />
          ) : (
            <Button
              arrow={false}
              buttonText={sequentialMessages[currentMessageIndex]}
              style={{
                color: 'var(--Neutral-300, #C9C9C9)',
                padding: "8px 22px",
                width:'100%',
                fontWeight: 450,
                fontSize: "16px",
                cursor: "not-allowed",
                backgroundColor: 'var(--Neutral-100, #F2F2F2)'
              }}
              disabled
            />
          )
        ) : (
          <Button
            arrow={false}
            buttonText={translation.openReport || "Open Report"}
            style={{
              padding: "8px 16px",
              width: "100%",
              fontWeight: 500,
            }}
          />
        )
      ) : (
        <Button
          arrow={false}
          // buttonText={`${translation.generatingReport} ${progressPercentage}%...`}
          buttonText={`Generating Report...`}
          style={{
            padding: "8px 16px",
            width: "100%",
            fontWeight: 500,
            textAlign: "center",
          }}
          disabled
        />
      )}
    </Box>
  );
};

export default DownloadPDFButton;
