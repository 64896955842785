import { Typography, Box } from "@mui/material";
import Frame from "images/children-portfolio-v2/LittlelearnerFrame.png";
import VerticalFrame from "images/children-portfolio-v2/littleLearnerFrameVertical.png";
import Image from "components/Image/Image";

const MultipleStory = ({
  scale,
  learningStories,
}: {
  scale: number;
  learningStories: {
    fundamental: any;
    milestone: any;
    image: string;
    story: string;
  }[];
}) => {
  const getImageStyles = (image: any) => {
    const imageObj = new window.Image();
    imageObj.src = image;
    const isVertical = imageObj.height > imageObj.width;
    return {
      frame: isVertical ? VerticalFrame : Frame,
      top: isVertical ? "22.5px" : "29px",
      left: isVertical ? "46px" : "24px",
      width: isVertical ? "61%" : "80%",
      height: isVertical ? "82%" : "56.9%",
    };
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "50px",
      }}
    >
      {learningStories.map((story, index) => {
        const { frame, top, left, width, height } = getImageStyles(
          learningStories[index].image
        );

        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              flexDirection: index % 2 === 0 ? "row" : "row-reverse",
            }}
          >
            <Box
              sx={{
                height: "233px",
                width: "280px",
                position: "relative",
              }}
            >
              <Image
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  zIndex: 5,
                  objectFit: "contain",
                }}
                src={frame}
                alt="frame"
              />
              <Image
                style={{
                  position: "absolute",
                  top: top,
                  left: left,
                  width: width,
                  height: height,
                  zIndex: 1,
                  objectFit: "cover",
                }}
                src={story.image}
                alt=""
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: `${350 * scale}px`,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#F9F6F1",
                  p: "24px",
                  width: "100%",
                  mt: "20px",
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "Futura PT",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "22px",
                    letterSpacing: "1.4px",
                    textTransform: "uppercase",
                  }}
                >
                  {story.fundamental.name}
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "Futura PT",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: `${20 * scale}px`,
                    letterSpacing: `${1.2 * scale}px`,
                  }}
                >
                  {story.milestone.name}
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "center",
                  fontFamily: "Futura PT",
                  fontSize: "10px",
                  fontWeight: 300,
                  lineHeight: "20px",
                  letterSpacing: "0.6px",
                  mt: 2,
                }}
              >
                {story.story}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default MultipleStory;
