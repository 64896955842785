import { RESOURCE_TYPE_TEXT } from "../containers/teacher/TeacherPage/teacherPageConstants";
import {
  API_Teacher_Module,
  API_Teacher_Lesson,
  API_Teacher_Text_Activity,
  API_Teacher_Video_Activity,
  API_Teacher_Flip_Card_Activity,
  API_Teacher_Mcq_Activity,
  API_Teacher_Interactive_Data_Entry_Activity,
  API_Teacher_Pictorial_Observation_Activity,
  API_Teacher_Community_Kanban_Board,
  API_Lesson_Discussion_Post,
  API_Teacher_Module_Note,
  API_Teacher_Module_Learner_Lesson_Progress,
  API_Teacher_Module_Review,
  API_Teacher_Display_Obtained_Certificate_Modal_Info,
} from "../types/teacher";
import { StoreSlice } from "./store";

export interface TeacherPageSlice {
  teacherModules: API_Teacher_Module[];
  fullScreen: boolean;
  filters: string[];
  teacherCurrentModule: API_Teacher_Module;
  teacherCurrentModuleReview: API_Teacher_Module_Review;
  teacherCurrentModuleNote: API_Teacher_Module_Note;
  teacherCurrentLesson: API_Teacher_Lesson;
  teacherCurrentActivity:
    | API_Teacher_Video_Activity
    | API_Teacher_Text_Activity
    | API_Teacher_Flip_Card_Activity
    | API_Teacher_Mcq_Activity
    | API_Teacher_Interactive_Data_Entry_Activity
    | API_Teacher_Pictorial_Observation_Activity;
  isTeacherModuleLoading: boolean;
  isRefreshTeacherModule: boolean;
  setTeacherModules: (teacherModules: API_Teacher_Module[]) => void;
  setFullScreen: (fullScreen: boolean) => void;
  setFilters: (filters: string[]) => void;
  setTeacherCurrentModule: (teacherCurrentModule: API_Teacher_Module) => void;
  setTeacherCurrentModuleReview: (
    teacherCurrentModuleReview: API_Teacher_Module_Review
  ) => void;
  setTeacherCurrentModuleNote: (
    teacherCurrentModuleNote: API_Teacher_Module_Note
  ) => void;
  setTeacherCurrentLesson: (teacherCurrentLesson: API_Teacher_Lesson) => void;
  setTeacherCurrentActivity: (
    teacherCurrentActivity:
      | API_Teacher_Video_Activity
      | API_Teacher_Text_Activity
      | API_Teacher_Flip_Card_Activity
      | API_Teacher_Mcq_Activity
      | API_Teacher_Interactive_Data_Entry_Activity
      | API_Teacher_Pictorial_Observation_Activity
  ) => void;
  setIsTeacherModuleLoading: (isTeacherModuleLoading: boolean) => void;
  setIsRefreshTeacherModule: (isRefreshTeacherModule: boolean) => void;
  kanbanBoardList: API_Teacher_Community_Kanban_Board[];
  setKanbanBoardList: (
    kanbanBoardList: API_Teacher_Community_Kanban_Board[]
  ) => void;
  lessonDiscussionPostList: API_Lesson_Discussion_Post[];
  setLessonDiscussionPostList: (
    lessonDiscussionPostList: API_Lesson_Discussion_Post[]
  ) => void;
  isLessonDiscussionPostLoading: boolean;
  setIsLessonDiscussionPostLoading: (
    isLessonDiscussionPostLoading: boolean
  ) => void;
  isRefreshLessonDiscussionPost: boolean;
  setIsRefreshLessonDiscussionPost: (
    isRefreshLessonDiscussionPost: boolean
  ) => void;
  learnerLessonProgressList: API_Teacher_Module_Learner_Lesson_Progress[];
  setLearnerLessonProgressList: (
    learnerLessonProgressList: API_Teacher_Module_Learner_Lesson_Progress[]
  ) => void;
  isOpenTeacherModuleReviewModal: boolean;
  setIsOpenTeacherModuleReviewModal: (
    isOpenTeacherModuleReviewModal: boolean
  ) => void;
  displayObtainedCertificateModalInfo: API_Teacher_Display_Obtained_Certificate_Modal_Info;
  setDisplayObtainedCertificateModalInfo: (
    displayObtainedCertificateModal: API_Teacher_Display_Obtained_Certificate_Modal_Info
  ) => void;
  isRefreshKanbanBoard: boolean;
  setIsRefreshKanbanBoard: (isRefreshKanbanBoard: boolean) => void;
}

export const createTeacherPageSlice: StoreSlice<TeacherPageSlice> = (
  set,
  get
) => ({
  teacherModules: [],
  fullScreen: false,
  filters: [],
  teacherCurrentModule: {
    id: -1,
    name: "",
    description: "",
    image: "",
    slug: "",
    weight: -1,
    is_published: false,
    lessons: [],
    avg_rating: -1,
    favorite: false,
    duration: -1,
  },
  teacherCurrentModuleReview: {
    id: -1,
    name: "",
    rating: -1,
    profile: -1,
    module: -1,
  },
  teacherCurrentModuleNote: {
    id: -1,
    name: "",
    profile: -1,
    teacher_module: -1,
    slug: "",
  },
  teacherCurrentLesson: {
    id: -1,
    name: "",
    description: "",
    discussion_topic: "",
    slug: "",
    image: "",
    module: -1,
    weight: -1,
    activities: [],
  },
  teacherCurrentActivity: {
    id: -1,
    lesson: -1,
    name: "",
    instruction: "",
    slug: "",
    description: "",
    is_published: false,
    resourcetype: RESOURCE_TYPE_TEXT,
    weight: 99,
  },
  isTeacherModuleLoading: true,
  isRefreshTeacherModule: true,
  setTeacherModules: (teacherModules: API_Teacher_Module[]) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      teacherModules: teacherModules,
    }));
  },
  setFullScreen: (fullScreen: boolean) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      fullScreen: fullScreen,
    }));
  },
  setFilters: (filters: string[]) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      filters: filters,
    }));
  },
  setTeacherCurrentModule: (teacherCurrentModule: API_Teacher_Module) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      teacherCurrentModule: teacherCurrentModule,
    }));
  },
  setTeacherCurrentModuleReview: (
    teacherCurrentModuleReview: API_Teacher_Module_Review
  ) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      teacherCurrentModuleReview: teacherCurrentModuleReview,
    }));
  },

  setTeacherCurrentModuleNote: (
    teacherCurrentModuleNote: API_Teacher_Module_Note
  ) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      teacherCurrentModuleNote: teacherCurrentModuleNote,
    }));
  },
  setTeacherCurrentLesson: (teacherCurrentLesson: API_Teacher_Lesson) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      teacherCurrentLesson: teacherCurrentLesson,
    }));
  },
  setTeacherCurrentActivity: (
    teacherCurrentActivity:
      | API_Teacher_Video_Activity
      | API_Teacher_Text_Activity
  ) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      teacherCurrentActivity: teacherCurrentActivity,
    }));
  },
  setIsTeacherModuleLoading: (isTeacherModuleLoading: boolean) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      isTeacherModuleLoading: isTeacherModuleLoading,
    }));
  },
  setIsRefreshTeacherModule: (isRefreshTeacherModule: boolean) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      isRefreshTeacherModule: isRefreshTeacherModule,
    }));
  },
  kanbanBoardList: [],
  setKanbanBoardList: (
    kanbanBoardList: API_Teacher_Community_Kanban_Board[]
  ) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      kanbanBoardList: kanbanBoardList,
    }));
  },
  lessonDiscussionPostList: [],
  setLessonDiscussionPostList: (
    lessonDiscussionPostList: API_Lesson_Discussion_Post[]
  ) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      lessonDiscussionPostList: lessonDiscussionPostList,
    }));
  },
  isRefreshLessonDiscussionPost: true,
  setIsRefreshLessonDiscussionPost: (
    isRefreshLessonDiscussionPost: boolean
  ) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      isRefreshLessonDiscussionPost: isRefreshLessonDiscussionPost,
    }));
  },
  isLessonDiscussionPostLoading: true,
  setIsLessonDiscussionPostLoading: (
    isLessonDiscussionPostLoading: boolean
  ) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      isLessonDiscussionPostLoading: isLessonDiscussionPostLoading,
    }));
  },
  learnerLessonProgressList: [],
  setLearnerLessonProgressList: (
    learnerLessonProgressList: API_Teacher_Module_Learner_Lesson_Progress[]
  ) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      learnerLessonProgressList: learnerLessonProgressList,
    }));
  },
  displayObtainedCertificateModalInfo: {
    isOpen: false,
    certificateName: "",
  },
  setDisplayObtainedCertificateModalInfo: (
    displayObtainedCertificateModalInfo: API_Teacher_Display_Obtained_Certificate_Modal_Info
  ) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      displayObtainedCertificateModalInfo: displayObtainedCertificateModalInfo,
    }));
  },
  isOpenTeacherModuleReviewModal: false,
  setIsOpenTeacherModuleReviewModal: (
    isOpenTeacherModuleReviewModal: boolean
  ) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      isOpenTeacherModuleReviewModal: isOpenTeacherModuleReviewModal,
    }));
  },
  isRefreshKanbanBoard: true,
  setIsRefreshKanbanBoard: (isRefreshKanbanBoard: boolean) => {
    set((prev: TeacherPageSlice) => ({
      ...prev,
      isRefreshKanbanBoard: isRefreshKanbanBoard,
    }));
  },
});
