import { Box, Typography } from "@mui/material";

interface Props {
  descriptions: string;
  scale?: number;
}

const MilestoneSection = ({ descriptions, scale = 1 }: Props) => (
  <Box
    sx={{
      display: "flex",
    }}
  >
    <Typography
      sx={{
        fontFamily: "FuturaBkBT",
        fontSize: 16 * scale,
        lineHeight: "16px",
        fontWeight: 300,
        letterSpacing: "1.2px",
        marginLeft: 0.5 * scale,
        marginRight: 0.7 * scale,
      }}
    >
      &#8226;
    </Typography>
    <Typography
      sx={{
        fontFamily: "FuturaLtBT",
        fontSize: 12 * scale,
        lineHeight: "16px",
        fontWeight: 300,
        letterSpacing: "1.2px",
        textAlign: "justify",
      }}
    >
      {descriptions}
    </Typography>
  </Box>
);

export default MilestoneSection;
