import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import DownloadIcon from "@mui/icons-material/Download";
import BookImg from "images/book.png";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import Image from "components/Image/Image";
import styles from "./Button.module.css";

export const FactoryButton = ({
  btnText,
  onClick,
  style,
  className = "",
  iconLeft = false,
  icon,
}: {
  btnText: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
  style?: React.CSSProperties;
  className?: string;
  iconLeft?: boolean;
  icon?: React.ReactElement;
  variant?: any;
}) => {
  return (
    <div
      className={`${styles.btn} ${styles.filled} ${className}`}
      style={style}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        onClick && onClick(event)
      }
      // disabled
    >
      {iconLeft && icon}
      {btnText}
      {iconLeft || icon}
    </div>
  );
};

export const AddToBagButton = ({
  btnText,
  onClick,
  style,
}: {
  btnText: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
  style?: React.CSSProperties;
}) => (
  <FactoryButton
    btnText={btnText}
    onClick={onClick}
    style={style}
    icon={<ShoppingBagIcon fontSize="small" />}
    iconLeft
    className={styles.btn2}
  />
);

export const DeleteButton = ({
  btnText,
  onClick,
  style,
  iconLeft,
}: {
  btnText: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
  style?: React.CSSProperties;
  iconLeft?: boolean;
}) => (
  <FactoryButton
    btnText={btnText}
    onClick={onClick}
    style={style}
    icon={<DeleteIcon />}
    className={styles.download_btn}
    iconLeft={iconLeft}
  />
);

export const EditButton = ({
  btnText,
  onClick,
  style,
}: {
  btnText: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
  style?: React.CSSProperties;
}) => (
  <FactoryButton
    btnText={btnText}
    onClick={onClick}
    style={style}
    icon={<EditIcon />}
    className={styles.download_btn}
  />
);

export const AddButton = ({
  btnText,
  onClick,
  style,
}: {
  btnText: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
  style?: React.CSSProperties;
}) => (
  <FactoryButton
    btnText={btnText}
    onClick={onClick}
    style={style}
    icon={<AddIcon />}
    className={styles.download_btn}
  />
);

export const DownloadButton = ({
  btnText,
  onClick,
  style,
  showIcon,
}: {
  btnText: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
  style?: React.CSSProperties;
  showIcon?: boolean;
}) => (
  <FactoryButton
    btnText={btnText}
    onClick={onClick}
    style={style}
    icon={<DownloadIcon sx={{ fontSize: "14px" }} />}
    className={styles.download_btn}
  />
);

export const BrowseProjectsButton = ({
  btnText = "Browse projects",
  onClick,
  style,
}: {
  btnText?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
  style?: React.CSSProperties;
}) => (
  <FactoryButton
    btnText={btnText}
    onClick={onClick}
    style={style}
    icon={
      <div style={{ width: "24px", height: "16px" }}>
        <Image src={BookImg} alt="browse" />
      </div>
    }
    className={styles.browse_project_btn}
    iconLeft
  />
);
