import React, { useState, useEffect } from "react";

import {
  Backdrop,
  Box,
  Fade,
  Modal,
  Typography,
  SxProps,
  Tooltip,
  IconButton,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CropIcon from "@mui/icons-material/Crop";

import Image from "../../Image/Image";
import Button from "../../Button/Button";
import { translation } from "constants/translation";
import { ImageType } from "./constant";

import EditPhoto from "../../PhotoVideoGalleryComponents/Photo/EditPhotoModal";

const SelectImageModal = ({
  open,
  setOpen,
  images,
  setImage,
  selectedImage,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  images: {
    id: number;
    file: string;
  }[];
  setImage: (image: ImageType) => void;
  selectedImage: ImageType;
}) => {
  const handleClose = () => setOpen(false);

  const [selected, setSelected] = useState<ImageType>(selectedImage);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string>("");
  const [imgName, setImgName] = useState<string>("");
  const [imgId, setImgId] = useState<number>(-1);
  const [editedImageInBlob, setEditedImageInBlob] = useState<
    string | undefined
  >();

  const handleEditImage = () => {
    const imgId = selected.id;
    const imgSrc = selected.file;
    const filePath = new URL(imgSrc).pathname.slice(1);
    const fileExtension = filePath.split(".").pop();
    const fileName = filePath.split("_")[0] + "." + fileExtension;

    fetch(imgSrc, {
      headers: { "Cache-Control": "no-cache" },
    })
      .then((res) => res.blob())
      .then((imageBlob) => setImgSrc(URL.createObjectURL(imageBlob)));

    setImgId(imgId);
    setImgName(fileName);
    setOpenEditModal(true);
  };

  const handleCloseEditImageModal = () => {
    setImgName("");
    setImgSrc("");
    setOpenEditModal(false);
  };

  useEffect(() => {
    if (editedImageInBlob === undefined && imgId === -1) return;

    const updateImage = () => {
      if (editedImageInBlob) {
        setSelected((prev) => ({ ...prev, file: editedImageInBlob }));
        setImgId(-1);
        setEditedImageInBlob(undefined);
      }
    };

    updateImage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedImageInBlob, imgId]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        sx={{
          m: "24px",
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              maxWidth: "950px",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: "5px 5px 20px var(--primary-main)",
              p: 4,
              borderRadius: "10px",
              overflow: "auto",
              maxHeight: "100%",
            }}
          >
            <Box sx={{ position: "relative" }}>
              <Image
                src={selected.file}
                style={{ maxHeight: "350px", margin: "auto" }}
                alt=""
              />

              <Tooltip title={translation.edit_image}>
                <IconButton
                  onClick={handleEditImage}
                  size="small"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CropIcon
                    sx={{
                      p: 0.25,
                      color: "txt.dark",
                      stroke: "currentcolor",
                      strokeWidth: "0.25px",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>

            <Typography
              sx={{
                textAlign: "center",
                m: "12px 0 24px",
              }}
            >
              {selected.caption ? selected.caption : ""}
            </Typography>

            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "500",
                mb: "8px",
              }}
            >
              {translation.pickImage}
            </Typography>

            <Box
              sx={{
                display: "flex",
                gap: "4px",
                flexWrap: "wrap",
                maxHeight: "204px",
                overflowY: "auto",
              }}
            >
              {images.map((image, index) => {
                const isSelected = selected.id === image.id;

                const iconStyle: SxProps = {
                  position: "absolute",
                  top: 4,
                  right: 4,
                  color: "txt.secondary",
                  cursor: "pointer",
                  opacity: isSelected ? 1 : 0,
                };

                return (
                  <Box
                    key={index}
                    sx={{
                      position: "relative",
                      cursor: "pointer",
                      opacity: isSelected ? 1 : 0.7,
                      transition: "all 200ms linear",
                      "&:hover": {
                        opacity: 1,
                        transition: "all 200ms linear",
                      },
                      height: "100px",
                      borderRadius: "10px",
                      overflow: "hidden",
                      border: "3px solid",
                      borderColor: isSelected ? "txt.secondary" : "transparent",
                      pointerEvents: isSelected ? "none" : "auto",
                    }}
                    onClick={() => setSelected(image)}
                  >
                    {isSelected ? (
                      <CheckBoxIcon className="icon" sx={iconStyle} />
                    ) : (
                      <CheckBoxOutlineBlankIcon
                        className="icon"
                        sx={iconStyle}
                      />
                    )}

                    <Image
                      src={image.file}
                      objectFit="cover"
                      style={{
                        width: "100%",
                        minWidth: "75px",
                        maxWidth: "150px",
                      }}
                      alt=""
                    />
                  </Box>
                );
              })}
            </Box>

            <Box
              sx={{
                mt: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                buttonText={translation.cancel || "Cancel"}
                arrow={false}
                style={{
                  fontSize: "14px",
                  backgroundColor: "transparent",
                  color: "var(--txt-secondary)",
                  padding: "0",
                  marginRight: "20px",
                }}
                onClick={handleClose}
              />

              <Button
                arrow={false}
                style={{
                  fontSize: "14px",
                  backgroundColor: "var(--txt-secondary)",
                }}
                buttonText={translation.done || "Done"}
                onClick={() => {
                  setImage(selected);
                  handleClose();
                }}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>

      {openEditModal && imgSrc !== "" && (
        <EditPhoto
          open={openEditModal}
          handleClose={handleCloseEditImageModal}
          imageSrc={imgSrc}
          imageName={imgName}
          toBlob={true}
          setEditedImageInBlob={setEditedImageInBlob}
        />
      )}
    </>
  );
};

export default SelectImageModal;
