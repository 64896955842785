import MultipleStory from "./MultipleStory";
import SingleStory from "./SingleStory";

const PageContent = ({
  learningStories,
}: {
  learningStories: {
    fundamental: string;
    milestone: string;
    image: string;
    story: string;
  }[];
}) => {
  return (
    <>
      {learningStories.length === 1 ? (
        <SingleStory learningStories={learningStories[0]} />
      ) : (
        <MultipleStory learningStories={learningStories} />
      )}
    </>
  );
};

export default PageContent;
