import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import { PDFInfoType } from "../../Preview/constant";
import PageContent from "./PageContent";
import { pageSize, styles } from "../constant";
import background from "images/children-portfolio-v2/LittleLearnerBackground.png";
import learningTitleImage from "images/children-portfolio-v2/learningTitleImage.png";
import { translation } from "constants/translation";
import { checkIsChineseChar } from "helper/helper";

const templateStyle = StyleSheet.create({
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  PageContainer: {
    position: "absolute",
    top: 50,
    left: 0,
    width: "100%",
    flexDirection: "column",
    height: "92%",
  },
  TitleSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "0px 24px",
    gap: 1,
  },
  TitleImage: {
    width: 40,
    height: 40,
    marginBottom: 20,
  },
  TitleText: {
    fontSize: 32,
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "normal",
    color: "#000",
  },
});

const LearningStoryPages = ({ PDFInfo }: { PDFInfo: PDFInfoType }) => {
  const chunkedStories = [];
  const maxChars = 445;

  for (let i = 0; i < PDFInfo.learningStories.length; i++) {
    const currentStory = PDFInfo.learningStories[i];
    if (currentStory.story.length > maxChars) {
      chunkedStories.push([currentStory]);
    } else {
      let nextIndex = i + 1;
      if (
        nextIndex <= PDFInfo.learningStories.length &&
        PDFInfo.learningStories[nextIndex]?.story.length < maxChars
      ) {
        chunkedStories.push([
          PDFInfo.learningStories[i],
          PDFInfo.learningStories[nextIndex],
        ]);
        i++;
      } else {
        chunkedStories.push([currentStory]);
      }
    }
  }

  const getFontFamily = (text: string) => {
    return checkIsChineseChar(text) ? "KaiTi" : "TheOnlyException";
  };

  return (
    <>
      {chunkedStories.map((stories: any, index: any) => (
        <Page key={index} size={pageSize} style={styles.body}>
          <Image src={background} style={templateStyle.backgroundImage} />
          <View style={templateStyle.PageContainer}>
            <View style={templateStyle.TitleSection}>
              <Image
                src={learningTitleImage}
                style={templateStyle.TitleImage}
              />
              <Text
                style={{
                  ...templateStyle.TitleText,
                  fontFamily: getFontFamily(translation.learningStories),
                }}
              >
                {translation.learningStories}
              </Text>
            </View>
            <PageContent learningStories={stories} />
          </View>
        </Page>
      ))}
    </>
  );
};

export default LearningStoryPages;
