import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import { checkIsChineseChar } from "helper/helper";
import Frame from "images/children-portfolio-v2/LittlelearnerFrame.png";
import VerticalFrame from "images/children-portfolio-v2/littleLearnerFrameVertical.png";

const templateStyle = StyleSheet.create({
  TwoStorySection: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  ImageFrame: {
    position: "relative",
  },
  StoryTwoTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "50%",
  },
  titleContainer: {
    backgroundColor: "#F9F6F1",
    padding: 24,
  },
  TitleTwoStory: {
    color: "#000",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 100,
    lineHeight: 1.5,
    letterSpacing: 1.4,
    fontStyle: "normal",
    textTransform: "uppercase",
  },
  SubtitleTwoStory: {
    color: "#000",
    textAlign: "center",
    fontSize: 10,
    fontWeight: 100,
    lineHeight: 1.2,
    letterSpacing: 1.2,
    fontStyle: "normal",
    top: "5px",
  },
  StoryContainer: {
    padding: 10,
    textAlign: "center",
  },
  StoryTwoStory: {
    color: "#000",
    textAlign: "center",
    fontSize: 10,
    lineHeight: 1.8,
    letterSpacing: "1.2px",
    fontWeight: 100,
    fontStyle: "normal",
  },
});

const MultipleStory = ({
  learningStories,
}: {
  learningStories: {
    fundamental: any;
    milestone: any;
    image: string;
    story: string;
  }[];
}) => {
  const getFontFamily = (text: string) => {
    return checkIsChineseChar(text) ? "KaiTi" : "FuturaBkBT";
  };

  const getImageOrientation = (image: any) => {
    const imageObj = new window.Image();
    imageObj.src = image;
    return imageObj.height > imageObj.width ? true : false;
  };

  return (
    <>
      {learningStories.map((story, index) => {
        const isVertical = getImageOrientation(story.image);
        return (
          <View
            key={index}
            style={{
              ...templateStyle.TwoStorySection,
              flexDirection: index % 2 === 0 ? "row" : "row-reverse",
            }}
          >
            {/* images and frame */}
            <View
              style={{
                ...templateStyle.ImageFrame,
                marginLeft: index % 2 === 0 ? "50px" : 0,
                marginRight: index % 2 === 0 ? 0 : "20px",

                // marginRight: "30px",
                // marginLeft: "40px",
                width: isVertical ? "210px" : "262ps",
                height: isVertical ? "262px" : "210px",
              }}
            >
              <Image
                src={{
                  uri: story.image,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: "",
                }}
                style={{
                  width: isVertical ? "75%" : "80%",
                  height: isVertical ? "82%" : "64%",
                  top: isVertical ? "9.5%" : "8%",
                  left: isVertical ? "12.5%" : "10.5%",
                  objectFit: "cover",
                }}
              />
              <Image
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                src={isVertical ? VerticalFrame : Frame}
              />
            </View>
            <View
              style={{
                ...templateStyle.StoryTwoTextContainer,
                marginLeft: index % 2 === 0 ? 0 : "50px",
                marginRight: index % 2 === 0 ? "20px" : 0,
              }}
            >
              <View style={templateStyle.titleContainer}>
                <Text
                  style={{
                    ...templateStyle.TitleTwoStory,
                    fontFamily: getFontFamily(story.fundamental.name),
                  }}
                >
                  {story.fundamental.name}
                </Text>
                <Text
                  style={{
                    ...templateStyle.SubtitleTwoStory,
                    fontFamily: "FuturaLtBT",
                  }}
                >
                  {story.milestone.name}
                </Text>
              </View>
              <View style={{ ...templateStyle.StoryContainer }}>
                <Text
                  style={{
                    ...templateStyle.StoryTwoStory,
                    fontFamily: checkIsChineseChar(story.story)
                      ? "KaiTi"
                      : "FuturaLtBT",
                    fontWeight: 300,
                  }}
                >
                  {story.story}
                </Text>
              </View>
            </View>
          </View>
        );
      })}
    </>
  );
};

export default MultipleStory;
