import React from "react";
import PageContent from "./PageContent";
import { PDFInfoType } from "../constant";

const LearningStoryPages = ({
  scale,
  PDFInfo,
}: {
  scale: number;
  PDFInfo: PDFInfoType;
}) => {
  const chunkedStories = [];
  for (let i = 0; i < PDFInfo.learningStories.length; i++) {
    const currentStory = PDFInfo.learningStories[i];
    if (currentStory.story.length > 439) {
      chunkedStories.push([currentStory]);
    } else {
      let nextIndex = i + 1;
      if (
        nextIndex <= PDFInfo.learningStories.length &&
        PDFInfo.learningStories[nextIndex]?.story.length < 439
      ) {
        chunkedStories.push([
          PDFInfo.learningStories[i],
          PDFInfo.learningStories[nextIndex],
        ]);
        i++;
      } else {
        chunkedStories.push([currentStory]);
      }
    }
  }
  return (
    <>
      {chunkedStories.map((stories, index) => (
        <PageContent key={index} scale={scale} learningStories={stories} />
      ))}
    </>
  );
};

export default LearningStoryPages;
