import background from "images/children-portfolio-v2/LittleLearnerBackground.png";
import learningTitleImage from "images/children-portfolio-v2/learningTitleImage.png";
import Page from "../Page";
import { Typography, Box } from "@mui/material";
import SingleStory from "./SingleStory";
import MultipleStory from "./MultipleStory";

const PageContent = ({
  scale,
  learningStories,
}: {
  scale: number;
  learningStories: {
    fundamental: string;
    milestone: string;
    image: string;
    story: string;
  }[];
}) => {
  return (
    <Page>
      <img
        src={background}
        alt="background"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
      <Box
        sx={{
          position: "absolute",
          top: `${50 * scale}px`,
          left: 0,
          width: "100%",
          height: "100%",
          paddingRight: `${20 * scale}px`,
          paddingLeft: `${40 * scale}px`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Title Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            gap: 1,
          }}
        >
          <Box>
            <img
              src={learningTitleImage}
              alt="Learning Title"
              style={{ height: "40px", width: "40px", marginBottom: "20px" }}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "TheOnlyException",
                fontSize: "32px",
                fontWeight: 400,
                fontStyle: "normal",
                lineHeight: "normal",
              }}
            >
              Learning Stories
            </Typography>
          </Box>
        </Box>
        <Box sx={{ height: "100%" }}>
          {learningStories.length === 1 ? (
            <SingleStory learningStories={learningStories[0]} scale={scale} />
          ) : (
            <MultipleStory learningStories={learningStories} scale={scale} />
          )}
        </Box>
        {/* Story Sections */}
      </Box>
    </Page>
  );
};

export default PageContent;
