import axios from "api/axiosConfig";
import { AxiosError } from "axios";

import {
  API_Teacher_Course,
  API_Teacher_Module,
  API_Teacher_Lesson,
  API_Teacher_Text_Activity,
  API_Teacher_Video_Activity,
  API_Edit_Teacher_Lesson,
  API_Edit_Teacher_Activity,
  API_Edit_Teacher_Activity_Order,
  API_Teacher_Module_Edit,
  API_Create_Teacher_Lesson,
  API_Teacher_Activity,
  API_Create_Teacher_Activity,
  API_Flip_Card,
  API_Image,
  API_Mcq_Question,
  API_Teacher_Mcq_Activity,
  API_Teacher_Interactive_Data_Entry_Activity,
  API_Drag_And_Drop_Answer_Pair,
  API_Teacher_Community_Kanban_Board,
  API_Teacher_Community_Kanban_Board_With_Card,
  API_Teacher_Create_Community_Kanban_Board,
  API_Teacher_Edit_Community_Kanban_Board,
  API_Teacher_Community_Kanban_Board_Card,
  API_Teacher_Create_Community_Kanban_Board_Card,
  API_Teacher_Edit_Community_Kanban_Board_Card,
  API_Lesson_Discussion_Post,
  API_Create_Lesson_Discussion_Post,
  API_Edit_Lesson_Discussion_Post,
  API_Create_Lesson_Discussion_Post_Comment,
  API_Edit_Lesson_Discussion_Post_Comment,
  API_Lesson_Discussion_Post_Comment,
  API_Teacher_Module_Note,
  API_Teacher_Module_Learner_Lesson_Progress,
  API_Interactive_Data_Entry_Prompt_Learner_Answer,
  API_Teacher_Pictorial_Observation_Activity_Learner_Answer,
  API_Teacher_Module_Learner_Progress,
  API_Teacher_Module_Analytic,
  API_Teacher_Module_Review_Create,
  API_Teacher_Module_Review,
  API_Teacher_Module_Create,
  API_Teacher_Module_Manage,
  API_Teacher_Module_Chart_Info,
  ViewMode,
  API_Teacher_Certificate,
  API_Teacher_Certificate_Create,
  API_Teacher_Certificate_Edit,
  API_Teacher_Check_Certificate,
  API_Teacher_Certificate_Chart_Info,
  API_Teacher_Module_Visualization,
  Lambda_Respond,
  NLP_Job_Status,
} from "types/teacher";
import {
  RESOURCE_TYPE_PICTORIAL_OBSERVATION,
  RESOURCE_TYPE_VIDEO,
} from "containers/teacher/TeacherPage/teacherPageConstants";
import { API_Profile_Certificate, API_Profile_Short } from "types/profile";
import {
  ACCESS_CONTROL,
  INSIGHTS,
  VISUALIZATION,
} from "constants/staticConstant";
import { Query_Data } from "types/project";

// export const getAllCategory = async () => {
//     const res = await axios.get('en/teacher/category/list');
//     const data: ProjectCategory[] = await res.data;
//     return data;
// }

//COURSE
export const getAllCourses = async () => {
  const res = await axios.get("en/teacher/course/list");
  const data: API_Teacher_Course[] = await res.data;
  return data;
};

//MODULE
export const getAllModules = async (language: string, query?: string) => {
  const res = await axios.get(
    `${language}/teacher/module/list?search=${query}`
  );
  const data: API_Teacher_Module[] = await res.data;
  return data;
};

export const getProgressedModules = async (
  language: string,
  query?: string
) => {
  const res = await axios.get(
    `${language}/teacher/module/list/progress?search=${query}`
  );
  const data: API_Teacher_Module[] = await res.data;
  return data;
};

export const getAllModulesForManagePage = async (
  language: string,
  pageMode: ViewMode,
  queryData: Query_Data
) => {
  const apiPathType =
    pageMode === (INSIGHTS as ViewMode)
      ? "analytic"
      : pageMode === (ACCESS_CONTROL as ViewMode)
      ? "access-control"
      : pageMode === (VISUALIZATION as ViewMode)
      ? "chart"
      : "access-control";

  const path =
    `${language}/teacher/module/list/${apiPathType}?` +
    `search=${queryData.query || ""}` +
    (queryData.startDate ? `&start_date=${queryData.startDate}` : "") +
    (queryData.endDate ? `&end_date=${queryData.endDate}` : "");

  const res = await axios.get(path);
  const data:
    | API_Teacher_Module_Manage[]
    | API_Teacher_Module_Analytic[]
    | API_Teacher_Module_Visualization = await res.data;
  return data;
};

export const getModuleChartInfo = async (
  language: string,
  moduleId: number,
  startDate: string,
  endDate: string,
  accessToken: string | null
) => {
  const path =
    `${language}/teacher/module/${moduleId}/chart-info?` +
    (startDate ? `&start_date=${startDate}` : "") +
    (endDate ? `&end_date=${endDate}` : "");

  const res = await axios.get(path, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_Teacher_Module_Chart_Info = await res.data;
  return data;
};

export const getModule = async (
  moduleId: string,
  accessToken: string | null
) => {
  const res = await axios.get(`en/teacher/module/${moduleId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_Teacher_Module = await res.data;
  return data;
};

export const bulkPublishModule = async (
  publish: boolean,
  moduleIds: string[],
  accessToken: string | null
) => {
  try {
    await axios.post(
      "en/teacher/module/publish",
      {
        publish,
        modules: moduleIds,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getPublishedActivityModule = async (
  moduleId: string,
  accessToken: string | null
) => {
  const res = await axios.get(`en/teacher/module/${moduleId}/for-edit`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_Teacher_Module = await res.data;
  return data;
};

export const getModuleBySlug = async (moduleSlug: string) => {
  const res = await axios.get(`en/teacher/module/${moduleSlug}`);
  const data: API_Teacher_Module = await res.data;
  return data;
};

export const getPublishedActivityModuleBySlug = async (moduleSlug: string) => {
  const res = await axios.get(`en/teacher/module/${moduleSlug}/for-edit`);
  const data: API_Teacher_Module = await res.data;
  return data;
};

export const createTeacherModule = async (
  language: string,
  moduleInfo: API_Teacher_Module_Create
) => {
  const body = {
    name: moduleInfo.name,
    description: moduleInfo.description,
    is_published: moduleInfo.is_published,
  };

  if (typeof moduleInfo.image !== "string") {
    Object.assign(body, { image: moduleInfo.image });
  }

  try {
    const res = await axios.post(`/${language}/teacher/module/create`, body, {
      headers: { "content-type": "multipart/form-data" },
    });
    const data: API_Teacher_Module = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const editTeacherModule = async (
  language: string,
  moduleData: API_Teacher_Module_Edit
) => {
  // if (typeof moduleData.image !== "string" || moduleData.image === null) {
  //   Object.assign(body, { image: moduleData.image });
  // }
  try {
    const res = await axios.patch(
      `/${language}/teacher/module/${moduleData.id}/update`,
      moduleData,
      {
        headers: { "content-type": "multipart/form-data" },
      }
    );
    const data: API_Teacher_Module = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteTeacherModule = async (id: number, accessToken: string) => {
  try {
    const res = await axios.delete(`/en/teacher/module/${id}/delete`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: API_Teacher_Module = await res.data;
    return {};
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

// Certificate
export const getAllCertificate = async (language: string, query?: string) => {
  const res = await axios.get(
    `${language}/teacher/certificate/list?search=${query}`
  );
  const data: API_Teacher_Certificate[] = await res.data;
  return data;
};

export const getAllCertificateForManagePage = async (
  language: string,
  pageMode: ViewMode,
  queryData: Query_Data
) => {
  // const apiPathType =
  //   pageMode === (INSIGHTS as ViewMode)
  //     ? "analytic"
  //     : pageMode === (ACCESS_CONTROL as ViewMode)
  //     ? "access-control"
  //     : pageMode === (VISUALIZATION as ViewMode)
  //     ? "chart"
  //     : "access-control";

  const path =
    `${language}/teacher/certificate/list?` +
    `search=${queryData.query || ""}` +
    (queryData.startDate ? `&start_date=${queryData.startDate}` : "") +
    (queryData.endDate ? `&end_date=${queryData.endDate}` : "");

  const res = await axios.get(path);
  const data: API_Teacher_Certificate[] = await res.data;
  return data;
};

export const getCertificateChartInfo = async (
  language: string,
  certificateId: number,
  startDate: string,
  endDate: string,
  accessToken: string | null
) => {
  const path =
    `${language}/teacher/certificate/${certificateId}/chart-info?` +
    (startDate ? `&start_date=${startDate}` : "") +
    (endDate ? `&end_date=${endDate}` : "");

  const res = await axios.get(path, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_Teacher_Certificate_Chart_Info = await res.data;
  return data;
};

export const getCertifiedProfileList = async (
  certificateId: number,
  accessToken: string | null
) => {
  const res = await axios.get(
    `en/teacher/certificate/${certificateId}/get-certified-profile-list`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data: API_Profile_Certificate[] = await res.data;
  return data;
};

// export const getCertificate = async (
//   certificateId: string,
//   accessToken: string | null
// ) => {
//   const res = await axios.get(`en/teacher/certificate/${certificateId}`, {
//     headers: {
//       Authorization: `Bearer ${accessToken}`,
//     },
//   });
//   const data: API_Teacher_Certificate = await res.data;
//   return data;
// };

// export const getCertificateByCredentialId = async (
//   credentialId: string,
//   accessToken: string | null
// ) => {
//   const res = await axios.get(`en/teacher/certificate/get-by-credential-id/${credentialId}`, {
//     headers: {
//       Authorization: `Bearer ${accessToken}`,
//     },
//   });
//   const data: API_Teacher_Certificate = await res.data;
//   return data;
// };

export const getCertificateBySlug = async (certificateSlug: string) => {
  const res = await axios.get(`en/teacher/certificate/${certificateSlug}`);
  const data: API_Teacher_Certificate = await res.data;
  return data;
};

export const createTeacherCertificate = async (
  language: string,
  certificateInfo: API_Teacher_Certificate_Create
) => {
  const body = {
    name: certificateInfo.name,
    description: certificateInfo.description,
    module: certificateInfo.module,
  };

  if (typeof certificateInfo.image !== "string") {
    Object.assign(body, { image: certificateInfo.image });
  }

  try {
    const res = await axios.post(
      `/${language}/teacher/certificate/create`,
      body,
      {
        headers: { "content-type": "multipart/form-data" },
      }
    );
    const data: API_Teacher_Certificate = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const editTeacherCertificate = async (
  language: string,
  certificateInfo: API_Teacher_Certificate_Edit
) => {
  const body = {
    id: certificateInfo.id,
    name: certificateInfo.name,
    description: certificateInfo.description,
    module: certificateInfo.module,
  };

  if (typeof certificateInfo.image !== "string") {
    Object.assign(body, { image: certificateInfo.image });
  }

  try {
    const res = await axios.patch(
      `/${language}/teacher/certificate/${certificateInfo.id}/update`,
      body,
      {
        headers: { "content-type": "multipart/form-data" },
      }
    );
    const data: API_Teacher_Module = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteTeacherCertificate = async (
  id: number,
  accessToken: string
) => {
  try {
    const res = await axios.delete(`/en/teacher/certificate/${id}/delete`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: API_Teacher_Certificate = await res.data;
    return {};
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//Check eligibility to certz
export const checkCertificateEligibility = async (
  activityId: number,
  accessToken: string
) => {
  // console.log("activityToUpdateList: ", activityToUpdateList);
  try {
    const res = await axios.post(
      `en/teacher/certificate/check-eligibility`,
      { teacher_activity: activityId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    // Return the list of completed activity id of that lesson
    const data: API_Teacher_Check_Certificate = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//Issue certz
export const issueCertificate = async (
  certificateId: number,
  accessToken: string,
  file: Blob
) => {
  // console.log("activityToUpdateList: ", activityToUpdateList);
  try {
    const res = await axios.post(
      `en/teacher/certificate/issue-certificate`,
      { teacher_certificate: certificateId, file: file },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "multipart/form-data",
        },
      }
    );
    // Return the list of completed activity id of that lesson
    const data: number[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//Module Review
export const getProfileModuleReview = async (moduleSlug: string) => {
  const res = await axios.get(
    `en/teacher/module-review/get?teacher_module_slug=${moduleSlug}`
  );
  const data: API_Teacher_Module_Review = await res.data;
  return data;
};

export const createTeacherModuleReview = async (
  language: string,
  moduleReviewInfo: API_Teacher_Module_Review_Create
) => {
  console.log("moduleReviewInfo: ", moduleReviewInfo);
  try {
    const res = await axios.post(
      `/${language}/teacher/module-review/create`,
      moduleReviewInfo
    );
    const data: API_Teacher_Module_Review = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getLearnerProgress = async (
  moduleId: number,
  accessToken: string | null
) => {
  const res = await axios.get(
    `en/teacher/module/${moduleId}/get-learner-progress`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data: API_Teacher_Module_Learner_Lesson_Progress[] = await res.data;
  return data;
};

// TO HANDLE LEARNER Module progression
export const getLearnerModuleProgressList = async (
  accessToken: string | null
) => {
  const res = await axios.get(
    `en/teacher/module/learner-module-progress/list`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data: API_Teacher_Module_Learner_Progress[] = await res.data;
  return data;
};

export const updateLearnerModuleEnrollment = async (
  moduleId: number,
  accessToken: string | null
) => {
  const res = await axios.post(
    `en/teacher/module/update-teacher-module-enrollment`,
    {
      teacher_module: moduleId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data: API_Teacher_Module_Learner_Progress = await res.data;
  return data;
};

// MODULE NOTE
// module-note/list
export const getProfileModuleNoteList = async () => {
  const res = await axios.get("en/teacher/module-note/list");
  const data: API_Teacher_Module_Note[] = await res.data;
  return data;
};

// module-note/<int:pk>
export const getProfileModuleNote = async (moduleSlug: string | undefined, activitySlug: string | undefined) => {
  const res = await axios.get(
    `en/teacher/module-note/get?teacher_module_slug=${moduleSlug}&slug=${activitySlug}`
  );
  const data: API_Teacher_Module_Note = await res.data;
  return data;
};

// module-note/<int:pk>/update
export const editProfileModuleNote = async (
  language: string,
  moduleNoteData: API_Teacher_Module_Note
) => {
  try {
    const res = await axios.patch(
      `/${language}/teacher/module-note/update`,
      moduleNoteData
    );
    const data: API_Teacher_Module_Note = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

// module-note/<int:pk>/delete
export const deleteProfileModuleNote = async (
  moduleNoteId: number,
  accessToken: string
) => {
  try {
    const res = await axios.delete(
      `/en/teacher/module-note/${moduleNoteId}/delete`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Teacher_Module_Note = await res.data;
    return {};
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

// LESSON
export const getAllLessons = async (accessToken: string | null) => {
  const res = await axios.get("en/teacher/lesson/list", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_Teacher_Lesson[] = await res.data;
  return data;
};

export const getLessonBySlug = async (language: string, lessonSlug: string) => {
  const res = await axios.get(
    `${language}/teacher/lesson/${lessonSlug}/get-by-slug`
  );
  const data: API_Teacher_Lesson = await res.data;
  return data;
};

export const getLessonById = async (currLanguage: string, lessonId: number) => {
  const res = await axios.get(`en/teacher/lesson/${lessonId}`);
  const data: API_Teacher_Lesson = await res.data;
  return data;
};

export const createTeacherLesson = async (
  language: string,
  lessonInfo: API_Create_Teacher_Lesson
) => {
  try {
    const res = await axios.post(`/en/teacher/lesson/create`, lessonInfo, {
      headers: { "content-type": "multipart/form-data" },
    });
    const data: API_Teacher_Lesson = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const editTeacherLesson = async ({
  language,
  teacherLessonData,
}: {
  language: string;
  teacherLessonData: API_Edit_Teacher_Lesson;
}) => {
  try {
    const res = await axios.patch(
      `/${language}/teacher/lesson/${teacherLessonData.id}/update`,
      teacherLessonData,
      {
        headers: { "content-type": "multipart/form-data" },
      }
    );
    const data: API_Teacher_Lesson = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteTeacherLesson = async (
  lessonId: number,
  accessToken: string
) => {
  try {
    const res = await axios.delete(`/en/teacher/lesson/${lessonId}/delete`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: API_Teacher_Lesson = await res.data;
    return {};
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

// LESSON DISCUSSION POST
export const getLessonDiscussionPostList = async (
  lessonSlug: string,
  language: string,
  accessToken: string | null
) => {
  const res = await axios.get(
    `${language}/teacher/lesson/discussion-post/list?lesson_slug=${lessonSlug}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data: API_Lesson_Discussion_Post[] = await res.data;
  return data;
};

export const createLessonDiscussionPost = async (
  language: string,
  postInfo: API_Create_Lesson_Discussion_Post
) => {
  try {
    const res = await axios.post(
      `/en/teacher/lesson/discussion-post/create`,
      postInfo
    );
    const data: API_Lesson_Discussion_Post = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const editLessonDiscussionPost = async ({
  language,
  editedPostInfo,
}: {
  language: string;
  editedPostInfo: API_Edit_Lesson_Discussion_Post;
}) => {
  try {
    const res = await axios.patch(
      `/${language}/teacher/lesson/discussion-post/${editedPostInfo.id}/update`,
      editedPostInfo
    );
    const data: API_Lesson_Discussion_Post = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteLessonDiscussionPost = async (
  lessonDiscussionPostId: number,
  accessToken: string
) => {
  try {
    const res = await axios.delete(
      `/en/teacher/lesson/discussion-post/${lessonDiscussionPostId}/delete`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Lesson_Discussion_Post = await res.data;
    return {};
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const likeLessonDiscussionPost = async (
  language: string,
  postId: number
) => {
  try {
    const res = await axios.post(
      `/${language}/teacher/lesson/discussion-post-like/${postId}/update`
    );
    const data: number[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

// LESSON DISCUSSION COMMENT

export const createLessonDiscussionComment = async (
  language: string,
  commentInfo: API_Create_Lesson_Discussion_Post_Comment
) => {
  try {
    const res = await axios.post(
      `/en/teacher/lesson/discussion-post/comment/create`,
      commentInfo
    );
    const data: API_Lesson_Discussion_Post_Comment = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const editLessonDiscussionPostComment = async ({
  language,
  editedCommentInfo,
}: {
  language: string;
  editedCommentInfo: API_Edit_Lesson_Discussion_Post_Comment;
}) => {
  try {
    const res = await axios.patch(
      `/${language}/teacher/lesson/discussion-post/comment/${editedCommentInfo.id}/update`,
      editedCommentInfo
    );
    const data: API_Lesson_Discussion_Post_Comment = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteLessonDiscussionPostComment = async (
  lessonDiscussionPostCommentId: number,
  accessToken: string
) => {
  try {
    const res = await axios.delete(
      `/en/teacher/lesson/discussion-post/comment/${lessonDiscussionPostCommentId}/delete`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Lesson_Discussion_Post_Comment = await res.data;
    return {};
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const likeLessonDiscussionPostComment = async (
  language: string,
  commentId: number
) => {
  try {
    const res = await axios.post(
      `/${language}/teacher/lesson/discussion-post/comment-like/${commentId}/update`
    );
    const data: number[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

// ACTIVITY
export const getActivityBySlug = async (
  language: string,
  activitySlug: string
) => {
  const res = await axios.get(
    `${language}/teacher/activity/${activitySlug}/get-by-slug`
  );
  const data: API_Teacher_Video_Activity | API_Teacher_Text_Activity =
    await res.data;
  return data;
};

export const getActivityById = async (
  currLanguage: string,
  activityId: number
) => {
  const res = await axios.get(`${currLanguage}/teacher/activity/${activityId}`);
  const data: API_Teacher_Activity = await res.data;
  return data;
};

// export const createTeacherActivity = async (
//   currLanguage: string,
//   activityData: API_Create_Teacher_Activity,
//   setIsCreatingActivity: React.Dispatch<React.SetStateAction<boolean>>,
//   setProgressPercentage: React.Dispatch<React.SetStateAction<number>>,
//   accessToken: string
// ) => {
//   console.log("activityData: ", activityData);
//   let custom_headers = { Authorization: `Bearer ${accessToken}` };
//   if (
//     activityData.resourcetype === RESOURCE_TYPE_VIDEO ||
//     activityData.resourcetype === RESOURCE_TYPE_PICTORIAL_OBSERVATION
//   ) {
//     custom_headers = Object.assign({}, custom_headers, {
//       "content-type": "multipart/form-data",
//     });
//   }
//   try {
//     setIsCreatingActivity(true);
//     const res = await axios.post(
//       `/${currLanguage}/teacher/activity/create`,
//       activityData,
//       {
//         headers: custom_headers,
//         onUploadProgress: (progressEvent) => {
//           const { loaded, total } = progressEvent;
//           const percentage = Math.floor((loaded * 100) / total);
//           setProgressPercentage(percentage);
//         },
//       }
//     );
//     const data: API_Teacher_Activity = await res.data;
//     return data;
//   } catch (error: unknown) {
//     if (error instanceof AxiosError && error.response) {
//       return JSON.stringify(error.response.data);
//     }
//     return "Error!";
//   } finally {
//     setIsCreatingActivity(false);
//     setProgressPercentage(0);
//   }
// }

export const createTeacherActivity = async (
  currLanguage: string,
  activityData: API_Create_Teacher_Activity,
  setIsCreatingActivity: React.Dispatch<React.SetStateAction<boolean>>,
  setProgressPercentage: React.Dispatch<React.SetStateAction<number>>,
  accessToken: string
) => {
  console.log("activityData: ", activityData);
  const customHeaders = {
    Authorization: `Bearer ${accessToken}`,
  };

  const formData = new FormData();
  Object.keys(activityData).forEach((key) => {
    if (key === "text_activity_components" && activityData[key]) {
      // Serialize text_activity_components JSON data
      formData.append(key, JSON.stringify(activityData[key]));
    } else if (key === "mcq_questions" && activityData[key]) {
      // Serialize mcq_questions JSON data
      formData.append(key, JSON.stringify(activityData[key]));
    } else if (key === "flip_cards" && activityData[key]) {
      // Serialize flip_cards JSON data
      formData.append(key, JSON.stringify(activityData[key]));
    } else if (key === "drag_and_drop_answer_pairs" && activityData[key]) {
      // Serialize drag_and_drop_answer_pairs JSON data
      formData.append(key, JSON.stringify(activityData[key]));
    } else if (key === "interactive_data_entry_prompts" && activityData[key]) {
      // Serialize interactive_data_entry_prompts JSON data
      formData.append(key, JSON.stringify(activityData[key]));
    } else if (key !== "flip_book") {
      // Append other fields
      formData.append(
        key,
        activityData[key as keyof typeof activityData] as Blob | string
      );
    }
  });

  // Append the file, if it exists
  if (activityData.flip_book) {
    formData.append(
      "flip_book",
      activityData.flip_book,
      activityData.flip_book.name
    );
  }

  try {
    setIsCreatingActivity(true);
    const response = await axios.post(
      `/${currLanguage}/teacher/activity/create`,
      formData, // Send FormData instead of JSON
      {
        headers: customHeaders,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          setProgressPercentage(percentage);
        },
      }
    );
    return response.data; // No need to cast explicitly here
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  } finally {
    setIsCreatingActivity(false);
    setProgressPercentage(0);
  }
};

// export const generateVideoTranscriptViaObjectId = async (
//   language: string,
//   activityId: string
// ) => {
//   console.log("Via Object Id:");
//   try {
//     const res = await axios.post(
//       `/${language}/teacher/video-activity/generate-transcript-via-object-id`,
//       {
//         activity_id: activityId,
//       }
//     );
//     const data: string = await res.data;
//     return data;
//   } catch (error: unknown) {
//     if (error instanceof AxiosError && error.response) {
//       return JSON.stringify(error.response.data);
//     }
//     return "Error!";
//   }
// };

export const generateVideoTranscriptViaUrl = async (
  language: string,
  mediaUrl: string,
  callbackUrl: string,
  authToken: string
) => {
  console.log("mediaUrl: ", mediaUrl);
  console.log("callbackUrl: ", callbackUrl);
  console.log("authToken: ", authToken);
  try {
    const res = await axios.post(
      `https://to7aow1wk3.execute-api.ap-southeast-1.amazonaws.com/dev/api/v1/transcribe`,
      {
        file_url: mediaUrl,
        callback_url: callbackUrl,
        auth_token: authToken,
      }
    );
    const data: Lambda_Respond = await res.data;
    if (data) {
      return data;
    } else {
      return "no job triggered";
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
  // try {
  //   const res = await axios.post(
  //     `/${language}/teacher/video-activity/generate-transcript-via-url`,
  //     {
  //       media_url: mediaUrl,
  //     }
  //   );
  //   const data: string = await res.data;
  //   return data;
  // } catch (error: unknown) {
  //   if (error instanceof AxiosError && error.response) {
  //     return JSON.stringify(error.response.data);
  //   }
  //   return "Error!";
  // }
};

export const getTranscribeJobStatus = async (jobId: string) => {
  try {
    const res = await axios.post(
      `https://to7aow1wk3.execute-api.ap-southeast-1.amazonaws.com/dev/api/v1/transcribe/status`,
      {
        job_id: jobId,
      }
      // {
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //   },
      // }
    );
    const data: NLP_Job_Status = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

// export const generateVideoTranscriptViaVideoFile = async (
//   language: string,
//   videoFile: File
// ) => {
//   console.log("via Video File:");
//   try {
//     const res = await axios.post(
//       `/${language}/teacher/video-activity/generate-transcript-via-video-file`,
//       {
//         video_file: videoFile,
//       },
//       {
//         headers: {
//           "content-type": "multipart/form-data",
//         },
//       }
//     );
//     const data: string = await res.data;
//     return data;
//   } catch (error: unknown) {
//     if (error instanceof AxiosError && error.response) {
//       return JSON.stringify(error.response.data);
//     }
//     return "Error!";
//   }
// };

export const editActivityOrder = async (
  language: string,
  activityToUpdateList: API_Edit_Teacher_Activity_Order[],
  accessToken: string | null
) => {
  // console.log("activityToUpdateList: ", activityToUpdateList);
  try {
    const res = await axios.post(
      language + `/teacher/activity/order/update`,
      { activity_to_update_list: activityToUpdateList },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Teacher_Video_Activity = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateLearnerActivityProgress = async (
  activityId: number,
  accessToken: string | null
) => {
  // console.log("activityToUpdateList: ", activityToUpdateList);
  try {
    const res = await axios.post(
      `en/teacher/activity/learner-progress/update`,
      { teacher_activity: activityId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    // Return the list of completed activity id of that lesson
    const data: number[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getInteractiveDataEntryLearnerAnswer = async (
  activityId: number,
  profileId: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.get(
      `en/teacher/interactive-data-entry-activity/${activityId}/get-learner-answer?profile=${profileId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Interactive_Data_Entry_Prompt_Learner_Answer[] =
      await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getInteractiveDataEntryLearnerList = async (
  activityId: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.get(
      `en/teacher/interactive-data-entry-activity/${activityId}/get-learner-list`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Profile_Short[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getPictorialObservationLearnerAnswer = async (
  activityId: number,
  profileId: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.get(
      `en/teacher/pictorial-observation-activity/${activityId}/get-learner-answer?profile=${profileId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const data: API_Teacher_Pictorial_Observation_Activity_Learner_Answer =
      await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getPictorialObservationLearnerList = async (
  activityId: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.get(
      `en/teacher/pictorial-observation-activity/${activityId}/get-learner-list`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Profile_Short[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateCreateInteractiveDataEntryPrompt = async (
  interactive_data_entry_prompt_answers: API_Interactive_Data_Entry_Prompt_Learner_Answer[],
  accessToken: string | null
) => {
  try {
    const res = await axios.post(
      `en/teacher/interactive-data-entry-prompt-learner-answer/create-or-update`,
      {
        interactive_data_entry_prompt_answers:
          interactive_data_entry_prompt_answers,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Interactive_Data_Entry_Prompt_Learner_Answer[] =
      await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateCreatePictorialObservation = async (
  pictorial_observation_answer: API_Teacher_Pictorial_Observation_Activity_Learner_Answer,
  accessToken: string | null
) => {
  try {
    const res = await axios.post(
      `en/teacher/pictorial-observation-activity-learner-answer/create-or-update`,
      {
        pictorial_observation_answer: pictorial_observation_answer,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Teacher_Pictorial_Observation_Activity_Learner_Answer =
      await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const editTeacherActivity = async (
  currLanguage: string,
  activityData: API_Edit_Teacher_Activity,
  setIsCreatingActivity: React.Dispatch<React.SetStateAction<boolean>>,
  setProgressPercentage: React.Dispatch<React.SetStateAction<number>>,
  accessToken: string
) => {
  // console.log("activityData: ", activityData);
  let custom_headers = { Authorization: `Bearer ${accessToken}` };
  if (
    activityData.resourcetype === RESOURCE_TYPE_VIDEO ||
    activityData.resourcetype === RESOURCE_TYPE_PICTORIAL_OBSERVATION
  ) {
    custom_headers = Object.assign({}, custom_headers, {
      "content-type": "multipart/form-data",
    });
  }
  try {
    setIsCreatingActivity(true);
    const res = await axios.patch(
      `/${currLanguage}/teacher/activity/${activityData.id}/update`,
      activityData,
      {
        headers: custom_headers,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          setProgressPercentage(percentage);
        },
      }
    );

    const data: API_Teacher_Activity = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  } finally {
    setIsCreatingActivity(false);
    setProgressPercentage(0);
  }
};

export const addTextActivityImage = async (
  currLanguage: string,
  textActivityId: number,
  imageFiles: File[],
  setIsCreatingActivity: React.Dispatch<React.SetStateAction<boolean>>,
  setProgressPercentage: React.Dispatch<React.SetStateAction<number>>,
  accessToken: string
) => {
  try {
    setIsCreatingActivity(true);
    const res = await axios.post(
      `/${currLanguage}/teacher/text-activity-image/add-image`,
      { text_activity: textActivityId, image_files: imageFiles },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          setProgressPercentage(percentage);
        },
      }
    );

    const data: API_Image[] = await res.data;
    // console.log("returned data: ", data);
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  } finally {
    setIsCreatingActivity(false);
    setProgressPercentage(0);
  }
};

export const deleteTeacherActivity = async (activityId: number) => {
  try {
    const res = await axios.delete(`/en/teacher/activity/${activityId}/delete`);
    const data: API_Teacher_Video_Activity = await res.data;
    return {};
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteTextActivityComponent = async (
  textActivityComponentId: number
) => {
  try {
    const res = await axios.delete(
      `/en/teacher/text-activity-component/${textActivityComponentId}/delete`
    );
    const data: API_Teacher_Text_Activity = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteTextActivityImage = async (textActivityImageId: number) => {
  try {
    const res = await axios.delete(
      `/en/teacher/text-activity-image/${textActivityImageId}/delete`
    );
    const data: API_Image = await res.data;
    return {};
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteFlipCardActivityText = async (flipCardTextId: number) => {
  try {
    const res = await axios.delete(
      `/en/teacher/flip-card-text-activity/${flipCardTextId}/delete`
    );
    const data: API_Flip_Card = await res.data;
    return {};
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteDragAndDropAnswerPair = async (
  dragAndDropAnswerPairId: number
) => {
  try {
    const res = await axios.delete(
      `/en/teacher/drag-and-drop-answer-pair/${dragAndDropAnswerPairId}/delete`
    );
    const data: API_Drag_And_Drop_Answer_Pair = await res.data;
    return {};
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteMcqQuestion = async (mcqQuestionId: number) => {
  try {
    const res = await axios.delete(
      `/en/teacher/mcq/question/${mcqQuestionId}/delete`
    );
    const data: API_Teacher_Mcq_Activity = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteMcqOption = async (mcqQuestionId: number) => {
  try {
    const res = await axios.delete(
      `/en/teacher/mcq/option/${mcqQuestionId}/delete`
    );
    const data: API_Mcq_Question = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteInteractiveDataEntryPrompt = async (
  interactiveDataEntryPromptId: number
) => {
  try {
    const res = await axios.delete(
      `/en/teacher/interactive-data-entry-prompt/${interactiveDataEntryPromptId}/delete`
    );
    const data: API_Teacher_Interactive_Data_Entry_Activity = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

// KANBAN API

export const getCommunityKanbanBoardList = async () => {
  const res = await axios.get("en/teacher/community-kanban-board/list");
  const data: API_Teacher_Community_Kanban_Board[] = await res.data;
  return data;
};
export const getCommunityKanbanBoard = async (
  kanbanBoardId: number,
  accessToken: string | null
) => {
  const res = await axios.get(
    `en/teacher/community-kanban-board/${kanbanBoardId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data: API_Teacher_Community_Kanban_Board_With_Card = await res.data;
  return data;
};

export const getCommunityKanbanBoardBySlug = async (
  language: string,
  kanbanBoardSlug: string
) => {
  const res = await axios.get(
    `${language}/teacher/community-kanban-board-slug/${kanbanBoardSlug}`
  );
  const data: API_Teacher_Community_Kanban_Board_With_Card = await res.data;
  return data;
};

export const deleteCommunityKanbanBoard = async (
  id: number,
  accessToken: string
) => {
  try {
    const res = await axios.delete(
      `/en/teacher/community-kanban-board/${id}/delete`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Teacher_Community_Kanban_Board[] = await res.data;
    return {};
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const createCommunityKanbanBoard = async (
  language: string,
  kanbanBoardInfo: API_Teacher_Create_Community_Kanban_Board
) => {
  try {
    const res = await axios.post(
      `/${language}/teacher/community-kanban-board/create`,
      kanbanBoardInfo
    );
    const data: API_Teacher_Community_Kanban_Board = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const editCommunityKanbanBoard = async (
  language: string,
  kanbanBoardData: API_Teacher_Edit_Community_Kanban_Board
) => {
  try {
    const res = await axios.patch(
      `/${language}/teacher/community-kanban-board/${kanbanBoardData.id}/update`,
      kanbanBoardData
    );
    const data: API_Teacher_Community_Kanban_Board = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//Kanban Board Card

export const deleteCommunityKanbanBoardCard = async (
  id: number,
  accessToken: string
) => {
  try {
    const res = await axios.delete(
      `/en/teacher/community-kanban-board-card/${id}/delete`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Teacher_Community_Kanban_Board_With_Card = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const createCommunityKanbanBoardCard = async (
  language: string,
  kanbanBoardCardInfo: API_Teacher_Create_Community_Kanban_Board_Card
) => {
  try {
    const res = await axios.post(
      `/${language}/teacher/community-kanban-board-card/create`,
      kanbanBoardCardInfo
    );
    const data: API_Teacher_Community_Kanban_Board_Card = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//Update
export const editCommunityKanbanBoardCard = async (
  language: string,
  kanbanBoardCardInfo: API_Teacher_Edit_Community_Kanban_Board_Card
) => {
  try {
    const res = await axios.patch(
      `/${language}/teacher/community-kanban-board-card/${kanbanBoardCardInfo.id}/update`,
      kanbanBoardCardInfo
    );
    const data: API_Teacher_Community_Kanban_Board_Card = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const editCommunityKanbanBoardCardWeight = async (
  language: string,
  kanbanBoardCardInfo: API_Teacher_Edit_Community_Kanban_Board_Card[]
) => {
  try {
    const res = await axios.post(
      `/${language}/teacher/community-kanban-board-card-weight/update`,
      {
        id_weight_pairs: kanbanBoardCardInfo,
      }
    );
    const data: API_Teacher_Community_Kanban_Board_With_Card = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const likeKanbanBoardCard = async (
  language: string,
  kanbanBoardCardId: number
) => {
  try {
    const res = await axios.post(
      `/${language}/teacher/community-kanban-board-card-like/${kanbanBoardCardId}/update`
    );
    const data: number[] = await res.data;
    console.log("data: ", data);
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};
