import { useState, useEffect } from "react";
import { Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer";

import { API_Project } from "types/project";
import { pageSize, imageSrc, styles } from "../ProjectReportPDF";
import KaiTi from "fonts/KaiTi.ttf";

import Background from "images/end-of-project-report/overview-bg.png";
import ImageTape from "images/end-of-project-report/overview-img-tape.png";
import { checkIsChineseChar } from "helper/helper";

import { PDFInfoType } from "../Preview/constant";
import { ECDH } from "crypto";

Font.register({
  family: "Kaiti",
  src: KaiTi,
});

const templateStyles = StyleSheet.create({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: 40,
    marginTop: 115,
    marginBottom: 124,
    marginLeft: 182,
    marginRight: 317,
  },
  title: {
    marginTop: 20,
    marginBottom: 40,
  },
  image: {
    position: "absolute",
    top: 480,
    left: 941,
    transformOrigin: "top left",
    transform: "rotate(-6deg)",
    width: 450,
    height: 280,
    objectFit: "cover",
  },
  imageShifted: {
    position: "absolute",
    top: 530,
    left: 1100,
    transformOrigin: "top left",
    transform: "rotate(-6deg)",
    width: 320,
    height: 200,
    objectFit: "cover",
  },
});

const Overview = ({
  PDFInfo,
  project,
}: {
  PDFInfo?: PDFInfoType;
  project: API_Project;
}) => {
  const [extendedText, setExtendedText] = useState<boolean>(false);
  const strip = (html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const description = PDFInfo?.overview || project.description || "";
  const isChinese = checkIsChineseChar(description);
  
  const checkExtendedText = () => {
    const textLength = isChinese ? 300 : 500;
    if (PDFInfo && strip(PDFInfo.overview).length > textLength) {
      setExtendedText(true);
    } else if (!PDFInfo && project.description.length > textLength) {
      setExtendedText(true);
    }
  };

  useEffect(() => {
    checkExtendedText();
  }, []);


  
  const addLineBreaks = (text: string, length: number) => {
    const regex = new RegExp(`.{1,${length}}`, "g");
    return text.match(regex)?.join("\n") || text;
  };

  const imageStyle = extendedText
  ? templateStyles.imageShifted
  : templateStyles.image;

const imageTapeStyle = extendedText
  ? styles.backgroundShifted
  : styles.background;


  return (
    <Page size={pageSize} style={styles.body}>
      <View style={styles.main} wrap={false}>
        <Image src={Background} style={styles.background} />

        <View style={templateStyles.wrapper}>
          <Text style={[styles.pageTitle, templateStyles.title]}>
            {PDFInfo ? PDFInfo.overviewTitle : "Project Overview"}
          </Text>

          {isChinese ? (
            <Text
              style={[
                styles.textCenter,
                styles.chineseFont,
                { width: 500, textAlign: "center", lineHeight: 1.5 },
              ]}
            >
              {addLineBreaks(strip(description), 35)}
            </Text>
          ) : (
            <Text style={styles.textCenter}>
              {strip(PDFInfo ? PDFInfo.overview : project.description)}
            </Text>
          )}
        </View>

        {project.images.length !== 0 && (
          <>
            <Image
              src={imageSrc(
                PDFInfo && PDFInfo.overviewImage
                  ? PDFInfo.overviewImage.file
                  : project.images[0].file
              )}
              style={imageStyle}
            />

            <Image src={ImageTape} style={imageTapeStyle} />
          </>
        )}
      </View>
    </Page>
  );
};

export default Overview;
