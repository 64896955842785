import React, { useState, useEffect } from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import { checkIsChineseChar } from "helper/helper";
import Frame from "images/children-portfolio-v2/LittlelearnerFrame.png";
import VerticalFrame from "images/children-portfolio-v2/littleLearnerFrameVertical.png";

// Custom hook to determine if an image is vertical
const useImageOrientation = (imageUrl: any) => {
  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    const img = new window.Image();
    img.src = imageUrl;
    img.onload = () => {
      if (img.height > img.width) {
        setIsVertical(true);
      }
    };
  }, [imageUrl]);

  return isVertical;
};

const templateStyle = StyleSheet.create({
  StorySection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  titleContainer: {
    backgroundColor: "#F9F6F1",
    padding: 10,
  },
  Title: {
    color: "#000",
    textAlign: "center",
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: 100,
    lineHeight: 1.5,
    letterSpacing: 1.4,
    fontStyle: "normal",
  },
  Subtitle: {
    color: "#000",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 100,
    lineHeight: 1.2,
    letterSpacing: 1.2,
    fontStyle: "normal",
    top: "5px",
  },
  StoryContainer: {
    padding: 10,
    textAlign: "center",
  },
  Story: {
    color: "#000",
    fontSize: 12,
    lineHeight: 1.8,
    letterSpacing: "1.2px",
    fontWeight: 100,
    fontStyle: "normal",
  },
});

interface LearningStory {
  image: string;
  fundamental: any;
  milestone: any;
  story: string;
}

const SingleStory = ({
  learningStories,
}: {
  learningStories: LearningStory;
}) => {
  // Use the custom hook to determine image orientation
  const isVertical = useImageOrientation(learningStories.image);

  // Function to get font family based on Chinese character presence
  const getFontFamily = (text: string) => {
    return checkIsChineseChar(text) ? "KaiTi" : "FuturaBkBT";
  };

  return (
    <View style={templateStyle.StorySection}>
      <View
        style={{
          position: "relative",
          width: "85%",
          height: "50%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Image
          src={learningStories.image}
          style={{
            position: "relative",
            top: isVertical ? "9.3%" : "9.5%",
            width: isVertical ? "45%" : "79.5%",
            height: isVertical ? "83%" : "70%",
            objectFit: "cover",
          }}
        />
        <Image
          style={{
            position: "absolute",
            top: 0,
            alignSelf: "center",
            width: isVertical ? "60%" : "100%",
            height: isVertical ? "100%" : "110%",
          }}
          src={isVertical ? VerticalFrame : Frame}
        />
      </View>
      <View
        style={{
          ...templateStyle.titleContainer,
          position: "relative",
          width: "80%",
          textAlign: "center",
        }}
      >
        <Text
          style={{
            ...templateStyle.Title,
            fontFamily: getFontFamily(learningStories.fundamental.name),
          }}
        >
          {learningStories.fundamental.name}
        </Text>
        <Text
          style={{
            ...templateStyle.Subtitle,
            fontFamily: getFontFamily(learningStories.milestone.name),
          }}
        >
          {learningStories.milestone.name}
        </Text>
      </View>
      <View
        style={{
          ...templateStyle.StoryContainer,
          position: "relative",
          textAlign: "center",
          width: "80%",
        }}
      >
        <Text
          style={{
            ...templateStyle.Story,
            fontFamily: getFontFamily(learningStories.story),
            fontWeight: 300,
          }}
        >
          {learningStories.story}
        </Text>
      </View>
    </View>
  );
};

export default SingleStory;
