import { Typography, Box } from "@mui/material";
import Frame from "images/children-portfolio-v2/LittlelearnerFrame.png";
import VerticalFrame from "images/children-portfolio-v2/littleLearnerFrameVertical.png";
import Image from "components/Image/Image";

const SingleStory = ({
  scale,
  learningStories,
}: {
  scale: number;
  learningStories: {
    fundamental: any;
    milestone: any;
    image: string;
    story: string;
  };
}) => {
  const { SingleFrame, frameHeight, top, width, height } = getSingleImageStyle(
    learningStories.image
  );

  function getSingleImageStyle(image: string) {
    const singleImageObject = new window.Image();
    singleImageObject.src = image;
    const isVertical = singleImageObject.height > singleImageObject.width;

    return {
      SingleFrame: isVertical ? VerticalFrame : Frame,
      frameHeight: isVertical ? "58%" : "75%",
      top: isVertical ? "37px" : "63px",
      width: isVertical ? "46%" : "79.5%",
      height: isVertical ? "48%" : "43%",
    };
  }

  return (
    <Box
      sx={{
        height: "90%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Image
          style={{
            justifySelf: "center",
            position: "absolute",
            width: "100%",
            height: frameHeight,
            zIndex: 3,
          }}
          src={SingleFrame}
          alt="frame"
        />
        <Image
          style={{
            position: "relative",
            top: top,
            left: 0,
            width: width,
            height: height,
            objectFit: "cover",
            zIndex: 1,
          }}
          src={learningStories.image}
          alt=""
        />
      </Box>
      <Box sx={{ position: "absolute", top: "55%" }}>
        <Box
          sx={{
            backgroundColor: "#F9F6F1",
            p: "24px",
            width: "450px",
          }}
        >
          <Typography
            sx={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Futura PT",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "22px",
              letterSpacing: "1.4px",
              textTransform: "uppercase",
            }}
          >
            {learningStories.fundamental.name}
          </Typography>
          <Typography
            sx={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Futura PT",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: `${20 * scale}px`,
              letterSpacing: `${1.2 * scale}px`,
            }}
          >
            {learningStories.milestone.name}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              position: "absolute",
              width: "100%",
              color: "#000",
              textAlign: "center",
              fontFamily: "Futura PT",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: "20px",
              letterSpacing: "1.2px",
              marginTop: "10px",
            }}
          >
            {learningStories.story}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleStory;
