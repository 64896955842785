
import notebackground from "images/notebackground.png"

import {
  Box,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { TabPanel } from "containers/ProjectPage/LessonScreens/CanvasComponents/Content";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import useStore from "store/store";
import { editProfileModuleNote, getProfileModuleNote } from "api/teacher-api";
import useSnack from "hooks/useSnack";
import { translation } from "constants/translation";
import NoteIcon from '@mui/icons-material/Note';

const FloatingNoteDiscussionComponent = () => {
  const { activitySlug, moduleSlug } = useParams();
  const openSnack = useSnack();
  const {
    teacherCurrentModuleNote,
    setTeacherCurrentModuleNote,
    currLanguage,
  } = useStore((state) => ({
    teacherCurrentModuleNote: state.teacherCurrentModuleNote,
    setTeacherCurrentModuleNote: state.setTeacherCurrentModuleNote,
    currLanguage: state.currLanguage,
  }));

  const [value, setValue] = useState<number>(0);
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [myNote, setMyNote] = useState<string>(teacherCurrentModuleNote.name);
  const [isDisplaySavedMsg, setIsDisplaySavedMsg] = useState<boolean>(false);
  const noteRef = useRef<HTMLDivElement>(null);

  const getNote = async () => {
    const moduleNoteRes = await getProfileModuleNote(moduleSlug, activitySlug);

    if (typeof moduleNoteRes !== "string") {
      setTeacherCurrentModuleNote(moduleNoteRes);
      setIsDisplaySavedMsg(true);
    } else {
      openSnack(moduleNoteRes, false);
    }
  }

  const updateNote = async () => {
    const moduleNoteRes = await editProfileModuleNote(currLanguage, {
      ...teacherCurrentModuleNote,
      name: myNote,
      slug: activitySlug
    });

    if (typeof moduleNoteRes !== "string") {
      setTeacherCurrentModuleNote(moduleNoteRes);
      setIsDisplaySavedMsg(true);
    } else {
      openSnack(moduleNoteRes, false);
    }
  };

  // Collapse when clicking outside the notes area
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (noteRef.current && !noteRef.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getNote();
  }, [activitySlug, moduleSlug]);

  useEffect(() => {
    setMyNote(teacherCurrentModuleNote.name || ""); // Fallback to empty if name is undefined
  }, [teacherCurrentModuleNote]);

  useEffect(() => {
    if (myNote === teacherCurrentModuleNote.name) return;

    const inputTextTimeout = setTimeout(async () => {
      if (myNote !== teacherCurrentModuleNote.name) {
        updateNote();
      }
    }, 600);
    return () => clearTimeout(inputTextTimeout);
  }, [myNote]);

  useEffect(() => {
    if (!isDisplaySavedMsg) return;

    const displayTimeout = setTimeout(async () => {
      setIsDisplaySavedMsg(false);
    }, 3000);
    return () => clearTimeout(displayTimeout);
  }, [isDisplaySavedMsg]);

  // console.log("teacherCurrentModuleNote: ", teacherCurrentModuleNote);

  return (
    <Box
      ref={noteRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: isExpanded ? "100%" : "min-content",
        border: "2px solid var(--primary-main)",
        borderRadius: "10px",
        p: 2,

        // transition: "500ms all ease-in-out",
      }}
    >
      <Box
        sx={{
          width: isExpanded ? "12vw" : "auto",
          height: '100%'


          // transition: "100ms all ease-in-out",
        }}
      >
        {isExpanded ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",

              }}
            >
              <Box sx={{ display: 'flex', gap: 1 }}>
                <NoteIcon sx={{ color: "txt.secondary" }} />
                <Typography sx={{ color: "txt.secondary", fontSize: '16px', fontWeight: '500' }}>My Note</Typography>
              </Box>
              <IconButton
                onClick={() => {
                  setIsExpanded(false);
                }}
              >
                <KeyboardArrowRightIcon sx={{ color: "txt.secondary" }} />
              </IconButton>
            </Box>

            <Box sx={{ height: "100%", position: "relative", }}>
              <Box sx={{
                backgroundImage: `url(${notebackground})`,
                backgroundRepeat: 'no-repeat',
                position: 'absolute',
                top: '40px',
                left: 0,
                right: 0,
                bottom: 0,
                overflow: "hidden",
                height: '90%'
              }}>
              </Box>
              <TabPanel value={value} index={0}>
                <Box
                  sx={{
                    lineHeight: 1.3,
                    height: '90%'
                  }}
                >
                  {isDisplaySavedMsg && (
                    <Typography
                      sx={{
                        color: "primary.main",
                        fontSize: 14,
                        fontStyle: "italic",
                        position: "absolute",
                        bottom: 5,
                        right: 2,
                      }}
                    >
                      {translation.noteChangesSaved}
                    </Typography>
                  )}
                  <TextField
                    multiline
                    // maxRows={26}
                    // minRows={26}
                    placeholder="Write your note here..."
                    fullWidth
                    value={myNote}
                    onChange={(event) => {
                      setMyNote(event.target.value);
                    }}
                    variant="standard"
                    style={{ fontSize: '100px' }}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        lineHeight: '24px',
                        height: '100%',
                      },
                    }}
                  />
                </Box>
              </TabPanel>
              {/* <TabPanel value={value} index={1}>
              <Box style={{ lineHeight: 1.3 }}> Discussion here </Box>
            </TabPanel> */}
            </Box>
          </>
        ) : (
          <>
            <Box
              onClick={() => {
                setIsExpanded(true);
                setValue(0);
              }}
              sx={{
                gap: 1,
                display: 'flex',
                "&:hover": {
                  cursor: 'pointer'
                }
              }}
            >
              <NoteIcon sx={{ color: "txt.secondary" }} />
              <Typography sx={{ color: "txt.secondary", fontSize: '16px', fontWeight: '500' }}>My Note</Typography>
            </Box>
            {/* <IconButton
            onClick={() => {
              setIsExpanded(true);
              setValue(1);
            }}
          >
            <QuestionAnswerIcon sx={{ color: "txt.secondary" }} />
          </IconButton> */}
          </>
        )}
      </Box>
    </Box>
  );
};

export default FloatingNoteDiscussionComponent;

